import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/red.css";
import { Link } from "react-router-dom";
import LogoLight from "../assets/img/logo-light.png";
import Config from "../../Config";
import { useContext } from "react";
import AppContext from "../../AppContext";

function Mobile() {
  const {translate} = useContext(AppContext)
  const t = key => {
    return translate(key)
  }

  return (
    <>
      <footer className="bg-navy text-inverse en">
        <div className="container pt-15 pt-md-17 pb-13 pb-md-15">
          <div className="d-lg-flex flex-row align-items-lg-center">
            <h3 className="display-4 mb-6 mb-lg-0 pe-lg-2-0 pe-xl-2-2 pe-xxl-2-5 text-white">
              {t("footer_title")}
            </h3>
            <Link
              onClick={(e) => {
                const _item = {
                  subsc_type: "month",
                  old_price: 0,
                  monthly_discount_perc: 100,
                  monthly_discount: 0,
                  price: 0,
                  is_free: true,
                };
                localStorage.setItem("subscription", JSON.stringify(_item));
              }}
              to="/Register"
              className="btn btn-primary rounded-pill mb-0 text-nowrap"
            >
              {t("footer_Register")}
            </Link>
          </div>

          <hr className="mt-11 mb-12" />
          <div className="row gy-6 gy-lg-0">
            <div className="col-md-4 col-lg-3">
              <div className="widget en">
                <img className="mb-4" src={LogoLight} alt="" />
                <p className="mb-4">
                  © 2022 Gymme. <br className="d-none d-lg-block" />
                  {t("footer_rights")}
                </p>
                <nav className="nav social social-white">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={Config?.socialMediaLinks?.twitter}
                  >
                    <i className="uil uil-twitter"></i>
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={Config?.socialMediaLinks?.facebook}
                  >
                    <i className="uil uil-facebook-f"></i>
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={Config?.socialMediaLinks?.linkedin}
                  >
                    <i className="uil uil-linkedin"></i>
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={Config?.socialMediaLinks?.insta}
                  >
                    <i className="uil uil-instagram"></i>
                  </a>
                </nav>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="widget en">
                <h4 className="widget-title text-white mb-3">
                  {t("Download")}
                </h4>
                <ul className="list-unstyled  mb-0">
                  <li>
                    <a
                      href={Config?.userIosApp}
                      target={"_blank"}
                      rel="noreferrer"
                      className="btn btn-outline-primary btn-icon btn-icon-start rounded me-2"
                    >
                      <i className="uil uil-apple"></i> {t("downApp")}
                    </a>
                  </li>
                  <li>
                    <a
                      href={Config?.userAndroidApp}
                      target={"_blank"}
                      rel="noreferrer"
                      className="btn btn-outline-primary btn-icon btn-icon-start rounded me-2"
                    >
                      <i className="uil uil-google-play"></i> {t("downGoogle")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="widget en">
                <h4 className="widget-title text-white mb-3">
                  {t("footer_Links")}
                </h4>
                <ul className="list-unstyled  mb-0">
                  <li>
                    <Link className="link-body" to="/Gyms">{t("Gyms")}</Link>
                  </li>
                  <li>
                    <Link className="link-body" to="/Mobile">{t("Mobile")}</Link>
                  </li>
                  <li>
                    <Link className="link-body" to="/About">{t("about_nav")}</Link>
                  </li>
                  <li>
                    <Link className="link-body" to="/Privacy">{t("Privacy")}</Link>
                  </li>
                  <li>
                    <Link className="link-body" to="/Terms">{t("terms")}</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-lg-3">
              <div className="widget en">
                <h4 className="widget-title text-white mb-3">
                  {t("footer_community")}
                </h4>
                <p className="mb-5">{t("footer_Join")}</p>
                <div className="newsletter-wrapper">
                  <div id="mc_embed_signup2">
                    <a
                      className="btn btn-primary rounded-pill mb-0 text-nowrap"
                      rel="noreferrer"
                      target="_blank"
                      href={Config?.socialMediaLinks?.fbCommunity}
                    >
                      {t("footer_Join_now")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Mobile;
