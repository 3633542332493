import DeviceImg from "../assets/img/imags/devices.png";
import DeviceImg2x from "../assets/img/imags/devices@2x.png";
import DevicesImg from "../assets/img/imags/device.png";
import DevicesImg2x from "../assets/img/imags/device@2x.png";
import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/red.css";
import Features from "../DATA/MobileFeatures";
import { useContext, useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import AOS from "aos";
import "../../../node_modules/aos/dist/aos.css";
import Config from "../../Config";
import AppContext from "../../AppContext";

function Mobile() {
  const { translate, systemLang } = useContext(AppContext);
  const t = (k) => translate(k);

  AOS.init({
    once: true,
  });

  return (
    <>
      <Navbar />
      <div className="mobile">
        <div className="wrapper bg-soft-primary ">
          <div className="container pt-5 pb-15 py-lg-17 py-xl-19 pb-xl-20 position-relative">
            <img
              className="position-lg-absolute col-12 col-lg-10 col-xl-11 col-xxl-10 px-lg-5 px-xl-0 ms-n5 ms-sm-n8 ms-md-n10 ms-lg-0 mb-md-4 mb-lg-0"
              src={DeviceImg}
              srcSet={DeviceImg2x}
              alt=""
              style={{ top: "-1%", left: "-21%" }}
            />
            <div className={`row gx-0 align-items-center ${systemLang}`}>
              <div className="col-md-10 offset-md-1 col-lg-5 offset-lg-7 offset-xxl-6 ps-xxl-12 mt-md-n9 text-center text-lg-start">
                <h1
                  className={`${
                    systemLang === "ar" ? "ar" : "en"
                  } display-2 mb-4 mx-sm-n2 mx-md-0"`}
                >
                  {t("mobile_title")}
                </h1>
                <p
                  className={`${
                    systemLang === "ar" ? "ar" : "en"
                  } lead fs-lg mb-7 px-md-10 px-lg-0`}
                >
                  {t("mobile_desription")}
                </p>
                <div className="d-flex justify-content-center justify-content-lg-start">
                  <span>
                    <a
                      href={Config?.userIosApp}
                      target={"_blank"}
                      rel="noreferrer"
                      className="btn btn-outline-primary btn-icon btn-icon-start rounded me"
                    >
                      <i className="uil uil-apple"></i> {t("downApp")}
                    </a>
                  </span>
                  <span>
                    <a
                      href={Config?.userAndroidApp}
                      target={"_blank"}
                      rel="noreferrer"
                      className="btn btn-outline-primary btn-icon btn-icon-start rounded hover-primary"
                    >
                      <i className="uil uil-google-play"></i> {t("downGoogle")}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper bg-light">
          <div className="container py-14 py-md-17">
            <div className="row text-center mt-xl-12">
              <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <h2 className="fs-15 text-uppercase text-muted mb-3">
                  {t("Features")}
                </h2>
                <h3
                  className={`${
                    systemLang === "ar" ? "ar" : "en"
                  } display-4 mb-9 px-xxl-11 text-center`}
                >
                  {t("Features_description")}
                </h3>
              </div>
            </div>

            <div className="row gx-lg-8 gx-xl-12 gy-8 mb-14 mb-md-17">
              {Features.map((x, index) => (
                <div className={`col-md-6 col-lg-4 ${systemLang}`} key={index}>
                  <div
                    className="d-flex flex-row"
                    data-aos="slide-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                  >
                    <div>
                      {/* <img
                      src={x.src}
                      className="icon-svg icon-svg-sm text-aqua me-4"
                      alt=""
                    /> */}
                      {x.src}
                    </div>
                    <div>
                      <h4 className="mb-1">
                        {systemLang === "ar" ? x.title_ar : x.title}
                      </h4>
                      <p className="mb-0">
                        {systemLang === "ar" ? x.description_ar : x.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              className={`row gx-lg-8 gx-xl-10 mb-lg-9 mb-xl-2 align-items-center ${systemLang}`}
            >
              <div className="col-lg-6">
                <figure>
                  <img
                    src={DevicesImg}
                    srcSet={DevicesImg2x}
                    data-aos="fade-in"
                    data-aos-offset="200"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    alt=""
                  />
                </figure>
              </div>
              <div className="col-lg-6">
                <h2 className="fs-15 text-uppercase text-muted mb-3">
                  {t("mobile_do")}
                </h2>
                <h3 className="display-4 mb-5">{t("mobile_Download")}</h3>
                <p className="mb-8">
                  {t("mobile_subscription")}
                  <br />
                  <br />
                  {t("mobile_track")}
                  <br />
                  <br />
                  {t("mobile_healthy")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Mobile;
