import Navbar from "../Navbar/Navbar";
import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/red.css";
import { useContext, useState } from "react";
import Owner from "../Form/GymOwnerForm";
import GymForm from "../Form/GymForm";
import Payment from "../Form/PaymentForm";
import Config from "../../Config";
import axios from "axios";
import RegisterAlert from "../Shared/Alerts/RegisterAlert";
import AppContext from "../../AppContext";

function Register() {
  const { translate, systemLang } = useContext(AppContext);
  const t = (k) => translate(k);

  const [showAlert, setShowAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [owner, setOwner] = useState({
    fName: "",
    lName: "",
    email: "",
    phone: "",
    password: "",
    password_confirmation: "",
  });
  const [gym, setGym] = useState({
    gym_name: "",
    gym_phone: "",
    gym_address: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const subscription = JSON.parse(localStorage.getItem("subscription"));

  const data = {
    gym_membership_id: subscription.id,
    gym_membership_type: subscription.subsc_type,
  };

  const [errors, setErrors] = useState({ ...owner, ...gym, ...data });

  const requestUrl = Config.apiBaseUrl + "/landing-page/landing";

  const registerMe = () => {
    setDisabled(true);

    const formData = { ...data, ...owner, ...gym };
    formData.name = `${formData?.fName} ${formData?.lName}`;
    delete formData.fName;
    delete formData.lName;

    Config.getHashForPrintToken()
      .then((hash) => {
        return axios.post(`${Config.apiBaseUrl}/print-token`, {
          hash: hash,
          type: "landing",
        });
      })
      .catch((err) => {
        setDisabled(false);
        console.log("Hash Generator Error", { ...err });
      })
      .then((printToken) => {
        if (subscription?.is_free) {
          freeRegister(printToken?.data?.token, formData);
        } else {
          normalRegister(printToken?.data?.token, formData);
        }
      })
      .catch((err) => {
        setDisabled(false);
        console.log("Print Token Error", { ...err });
      });

    // localStorage.removeItem('subscription')
  };

  const normalRegister = (token, formData) => {
    axios
      .post(`${requestUrl}/registerGym?token=${token}`, formData)
      .then(() => {
        setDisabled(false);
        setShowAlert(true);
      })
      .catch((err) => {
        const error = { ...err };
        const validation = error.response?.data?.errors;
        if (validation) {
          setErrors(validation);
          setDisabled(false);
        } else if (error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
        } else {
          setDisabled(false);
        }
      });
  };

  const freeRegister = (token, formData) => {
    axios
      .post(`${requestUrl}/free-register?token=${token}`, formData)
      .then(() => {
        setDisabled(false);
        setShowAlert(true);
      })
      .catch((err) => {
        const error = { ...err };
        const validation = error.response?.data?.errors;
        if (validation) {
          setErrors(validation);
          setDisabled(false);
        } else if (error?.response?.data?.message) {
          setErrorMessage(error?.response?.data?.message);
        } else {
          setDisabled(false);
        }
      });
  };

  const changeOwnerAttr = (attr, value) => {
    const tempOwner = { ...owner };
    tempOwner[attr] = value;
    setOwner(tempOwner);
    const tempErrors = { ...errors };
    tempErrors[attr === "fName" || attr === "lName" ? "name" : attr] = "";
    setErrors(tempErrors);
  };

  const changeGymAttr = (attr, value) => {
    const tempGym = { ...gym };
    tempGym[attr] = value;
    setGym(tempGym);
    const tempErrors = { ...errors };
    tempErrors[attr] = "";
    setErrors(tempErrors);
  };

  return (
    <>
      {showAlert ? (
        <RegisterAlert message={errorMessage ? errorMessage : undefined} />
      ) : null}
      <Navbar />
      <section className={`wrapper bg-light ${systemLang}`}>
        <div className="container pt-12 pt-md-14 pb-14 pb-md-16">
          <div className="row gx-md-8 gx-xl-12 gy-12">
            <div className={`col-lg-8 ${systemLang}`}>
              <h3 className="mb-4">{t("Register_owner")}</h3>
              <Owner data={owner} setData={changeOwnerAttr} errors={errors} />
              <h3 className="my-4">{t("Register_information")}</h3>
              <GymForm data={gym} setData={changeGymAttr} errors={errors} />
              {/* <hr className="mt-7 mb-6" />
              <h3 className="mb-4">{t("Register_Payment")}</h3>
              <Payment /> */}
            </div>
            <div className={`col-lg-4 ${systemLang}`}>
              <h3 className="mb-4">{t("Register_Summary")}</h3>
              {/* <div className="shopping-cart mb-7 en">
                <div className="shopping-cart-item d-flex justify-content-between mb-4">
                  <div className="d-flex flex-row d-flex align-items-center en">
                    <figure className="rounded w-17">
                      <a href="#!">
                        <img src={Img} alt=""/>
                      </a>
                    </figure>
                    <div className="w-100 ms">
                      <h3 className="post-title h6 lh-xs mb-1">
                        <a href="#!" className="link-dark"> {t("Register_Nike")} </a>
                      </h3>
                      <div className="small"> {t("Register_Color")}: {t("Register_Black")} </div>
                      <div className="small">{t("Register_Size")}: 43</div>
                    </div>
                  </div>
                  <div className="ms-2 d-flex align-items-center">
                    <p className="price fs-sm">
                      <span className="amount">$45.99</span>
                    </p>
                  </div>
                </div>
              </div> */}
              <div className={`table-responsive ${systemLang}`}>
                <table className="table table-order">
                  <tbody>
                    <tr>
                      <td className="ps-0">
                        <strong className="text-dark">
                          {t("Register_Subtotal")}
                        </strong>
                      </td>
                      <td className="pe-0 text-end">
                        <p className="price">
                          {subscription?.subsc_type === "year"
                            ? subscription?.yearly_old_price
                            : subscription?.old_price}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="ps-0">
                        <strong className="text-dark">
                          {t("Register_Discount")}(
                          {subscription?.subsc_type === "year"
                            ? subscription?.yearly_discount_perc
                            : subscription?.monthly_discount_perc}
                          %)
                        </strong>
                      </td>
                      <td className="pe-0 text-end">
                        <p className="price text-red">
                          {subscription?.subsc_type === "year"
                            ? subscription?.yearly_discount
                            : subscription?.monthly_discount}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td className="ps-0">
                        <strong className="text-dark">
                          {t("Register_Total")}
                        </strong>
                      </td>
                      <td className="pe-0 text-end">
                        <p className="price text-dark fw-bold">
                          {subscription?.subsc_type === "year"
                            ? subscription?.yearly_price
                            : subscription?.price}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Payment />
              <button
                onClick={registerMe}
                disabled={disabled}
                className="btn btn-primary rounded w-100 mt-4"
              >
                {" "}
                {t("Register_Confirm_payment")}{" "}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Register;
