import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/red.css";
import { useParams } from "react-router";
import Jobs from "../DATA/Jobs";
import Navbar from "../Navbar/Navbar";
import React from "react";

function Description() {
  const params = useParams();
  const project = Jobs.filter((d) => d.id === parseInt(params.id))[0];

  return (
    <>
      <Navbar />
      <section className="wrapper bg-soft-primary">
        <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
          <div className="row">
            <div className="col-md-10 col-xl-8 mx-auto">
              <div className="post-header">
                <h1 className="display-1 mb-5">{project.name_en}</h1>
                <ul className="post-meta fs-17 mb-5">
                  <li>
                    <i className="uil uil-clock"></i> {project.time_en}
                  </li>
                  <li>
                    <i className="uil uil-location-arrow"></i>{" "}
                    {project.location_en}
                  </li>
                  <li>
                    <i className="uil uil-building"></i> {project.category}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container pb-14 pb-md-16">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="blog single mt-n17">
                <div className="card shadow-lg">
                  <div className="card-body">
                    <h2 className="h1 mb-3">Job Description</h2>
                    <p>{project.Description}</p>
                    <h3 className="h2 mb-3 mt-9">Responsibilities</h3>
                    <p>
                      Cum sociis natoque penatibus et magnis dis parturient
                      montes, nascetur ridiculus mus. Cras justo odio, dapibus
                      ac facilisis in, egestas eget quam. Praesent commodo
                      cursus magna, vel scelerisque nisl consectetur et.
                    </p>
                    <div className="row gy-3 gx-xl-8">
                      <div className="col-xl-6">
                        <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                          <li>
                            <span>
                              <i className="uil uil-check"></i>
                            </span>
                            <span>
                              Aenean eu leo quam ornare curabitur blandit
                              tempus.
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="uil uil-check"></i>
                            </span>
                            <span>
                              Nullam quis risus eget urna mollis ornare donec
                              elit.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-xl-6">
                        <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                          <li>
                            <span>
                              <i className="uil uil-check"></i>
                            </span>
                            <span>
                              Etiam porta sem malesuada magna mollis euismod.
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="uil uil-check"></i>
                            </span>
                            <span>
                              Fermentum massa vivamus faucibus amet euismod.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <h3 className="h2 mb-3 mt-9">Requirements</h3>
                    <p>
                      Cum sociis natoque penatibus et magnis dis parturient
                      montes, nascetur ridiculus mus. Cras justo odio, dapibus
                      ac facilisis in, egestas eget quam. Praesent commodo
                      cursus magna, vel scelerisque nisl consectetur et.
                    </p>
                    <div className="row gy-3 gx-xl-8 mb-10">
                      <div className="col-xl-6">
                        <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                          <li>
                            <span>
                              <i className="uil uil-check"></i>
                            </span>
                            <span>
                              Aenean eu leo quam ornare curabitur blandit
                              tempus.
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="uil uil-check"></i>
                            </span>
                            <span>
                              Nullam quis risus eget urna mollis ornare donec
                              elit.
                            </span>
                          </li>
                        </ul>
                      </div>
                      <div className="col-xl-6">
                        <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                          <li>
                            <span>
                              <i className="uil uil-check"></i>
                            </span>
                            <span>
                              Etiam porta sem malesuada magna mollis euismod.
                            </span>
                          </li>
                          <li>
                            <span>
                              <i className="uil uil-check"></i>
                            </span>
                            <span>
                              Fermentum massa vivamus faucibus amet euismod.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <a
                      href="mailto:apply@email.com"
                      className="btn btn-primary rounded-pill"
                    >
                      Apply Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper bg-soft-primary">
        <div className="container py-14 py-md-16">
          <div className="row align-items-center mb-6">
            <div className="col-md-8 col-lg-9 col-xl-8 col-xxl-7 pe-xl-20">
              <h2 className="display-6 mb-0">More Job Openings</h2>
            </div>
            <div className="col-md-4 col-lg-3 ms-md-auto text-md-end mt-5 mt-md-0">
              <a href="#!" className="btn btn-primary rounded-pill mb-0">
                Explore Positions
              </a>
            </div>
          </div>
          <div className="row gy-6">
            {Jobs.map((x, index) => (
              <div className="col-md-6 col-lg-4" key={index}>
                <a href="#!" className="card shadow-lg lift h-100">
                  <div className="card-body p-5 d-flex flex-row">
                    <div>
                      <span className={x.class}>{x.avatar}</span>
                    </div>
                    <div>
                      <span className={x.class_jop}>{x.time_en}</span>
                      <h4 className="mb-1">{x.name_en}</h4>
                      <p className="mb-0 text-body">{x.location_en}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default Description;
