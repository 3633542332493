import { Link } from "react-router-dom";
import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/red.css";
import { ReactComponent as CareerImg } from "../assets/img/icons/solid/target.svg";
import { ReactComponent as VideoCallImg } from "../assets/img/icons/solid/videocall.svg";
import { ReactComponent as WalletImg } from "../assets/img/icons/solid/wallet.svg";
import { ReactComponent as AlarmImg } from "../assets/img/icons/solid/alarm.svg";
import Navbar from "../Navbar/Navbar";
import { useState, useContext } from "react";
import AppContext from "../../AppContext";

function Careers(props) {
  const { translate, systemLang } = useContext(AppContext);
  const t = (k) => translate(k);
  const [jobList, setJobList] = useState([]);
  const [selectJob, setSelectJob] = useState({ position: "", type: "" });

  return (
    <>
      <Navbar />
      <section className="wrapper bg-soft-primary position-relative">
        <div className="container pt-10 pb-17 pt-md-14 text-center">
          <div className="row">
            <div className="col-lg-8 mx-auto mb-5">
              <h1 className="fs-15 text-uppercase text-muted mb-3 text-primary">
                {t("career_title")}
              </h1>
              <h3
                className={`${
                  systemLang === "ar" ? "ar" : "en"
                } display-1 mb-6 text-center`}
              >
                {t("career_description")}
              </h3>
              <a href="#id" className="btn btn-lg btn-primary rounded-pill">
                {t("career_button")}
              </a>
            </div>
          </div>
        </div>
        <div className="overflow-hidden">
          <div className="divider text-light mx-n2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 60">
              <path
                fill="currentColor"
                d="M0,0V60H1440V0A5771,5771,0,0,1,0,0Z"
              />
            </svg>
          </div>
        </div>
      </section>
      <section className="wrapper bg-light">
        <div className="container pb-15 pb-md-17">
          <div className={`row gx-md-5 gy-5 mt-n18 mt-md-n19 ${systemLang}`}>
            <div className="col-md-6 col-xl-3">
              <div className="card lift" style={{height: '100%'}}>
                <div className="card-body">
                  <CareerImg
                    className="svg-inject icon-svg icon-svg-sm solid-mono text-primary mb-3"
                    alt=""
                  />
                  <h4>{t("career_Career")}</h4>
                  <p className="mb-0">{t("career_Career_description")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card lift" style={{height: '100%'}}>
                <div className="card-body">
                  <VideoCallImg
                    className="svg-inject icon-svg icon-svg-sm solid-mono text-primary mb-3"
                    alt=""
                  />
                  <h4>{t("career_Work")}</h4>
                  <p className="mb-0">{t("career_Work_description")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card lift" style={{height: '100%'}}>
                <div className="card-body">
                  <WalletImg
                    className="svg-inject icon-svg icon-svg-sm solid-mono text-primary mb-3"
                    alt=""
                  />
                  <h4>{t("career_Salary")}</h4>
                  <p className="mb-0">{t("career_Salary_description")}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <div className="card lift" style={{height: '100%'}}>
                <div className="card-body">
                  <AlarmImg
                    className="svg-inject icon-svg icon-svg-sm solid-mono text-primary mb-3"
                    alt=""
                  />
                  <h4>{t("career_Flexible")}</h4>
                  <p className="mb-0">{t("career_Flexible_description")}</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-14 my-md-17" id="id" />
          <div className="row text-center">
            <div className="col-xl-10 mx-auto">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                {t("career_Job")}
              </h2>
              <h3
                className={`${
                  systemLang === "ar" ? "ar" : "en"
                } display-4 mb-10 px-xxl-15 text-center`}
              >
                {t("career_Job_description")}
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-10 mx-auto">
              <form className="filter-form mb-10">
                <div className="row">
                  <div className="col-md-6 mb-6">
                    <div className="form-select-wrapper">
                      <select
                        className="form-select"
                        aria-label=""
                        onChange={(e) =>
                          setSelectJob({
                            ...selectJob,
                            position: e.target.value,
                          })
                        }
                      >
                        <option default selected value="">
                          {t("Position")}
                        </option>
                        <option value="Business">{t("Business")}</option>
                        <option value="Design">{t("Design")}</option>
                        <option value="Development">{t("Development")}</option>
                        <option value="Engineering">{t("Engineering")}</option>
                        <option value="Finance">{t("Finance")}</option>
                        <option value="Marketing">{t("Marketing")}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 mb-6">
                    <div className="form-select-wrapper">
                      <select
                        className="form-select"
                        aria-label=""
                        onChange={(e) =>
                          setSelectJob({ ...selectJob, type: e.target.value })
                        }
                      >
                        <option default selected value="">
                          {t("Type")}
                        </option>
                        <option value="FullTime">{t("FullTime")}</option>
                        <option value="PartTime">{t("PartTime")}</option>
                        <option value="Remote">{t("Remote")}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              {Object.entries(jobList).map((x, index) => (
                <div className="job-list mb-10" key={index}>
                  <h3 className="mb-4">{x[0]}</h3>
                  {x[1].map((jobItem, _index) => (
                    <div>
                      <Link
                        to={`/Job-description/${jobItem.id}`}
                        className="card mb-4 lift"
                      >
                        <div className="card-body p-5">
                          <span className="row justify-content-between align-items-center">
                            <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                              <span className={jobItem.class}>
                                {jobItem.avatar}
                              </span>{" "}
                              {jobItem.name_en}{" "}
                            </span>
                            <span className="col-5 col-md-3 text-body d-flex align-items-center">
                              <i className="uil uil-clock me-1"></i>{" "}
                              {jobItem.time_en}
                            </span>
                            <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                              <i className="uil uil-location-arrow me-1"></i>{" "}
                              {jobItem.location_en}
                            </span>
                            <span className="d-none d-lg-block col-1 text-center text-body">
                              <i className="uil uil-angle-right-b"></i>
                            </span>
                          </span>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              ))}
              {selectJob?.position || selectJob?.type ? (
                <h3 className="text-uppercase text-muted mb-3 text-primary text-center">
                  {t("NoVacancies")}
                </h3>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Careers;
