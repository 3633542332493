export const TRANSLATIONS_ar = {
  home: "الرئيسية",
  Gyms: "جيمات",
  Careers: "وظائف",
  Mobile: "الهاتف",
  contact: "تواصل معنا",
  about_nav: "معلومات عن Gymme",
  terms: "شروط الاستخدام",
  Privacy: "سياسة الخصوصية",
  En: "English 🇺🇸",
  Ar: "العربية 🇪🇬",
  gym_title: "لم تكن إدارة صالة الألعاب الرياضية الخاصة بك أسهل من أي وقت مضى",
  gym_description: "جربه الآن مجانًا وابدأ في جمع الأموال.",
  gym_button_Started: "البدء",
  gym_button_Register: "سجل الان مجانا",
  gym_Pricing: "أسعارنا",
  gym_Monthly: "شهريا",
  gym_Yearly: "سنويا",
  gym_Month: "جنيه مصري/شهريا",
  gym_Year: "جنيه مصري/سنويا",
  gym_Save: "وفر",
  gym_FAQ: "الأسئلة الشائعة",
  gym_question:
    "إذا لم تجد إجابة على سؤالك ، يمكنك إرسال بريد إلكتروني إلينا عن طريق نموذج الاتصال الخاص بنا.",
  gym_Create:
    "أنشئ صالة الألعاب الرياضية الخاصة بك ، وأضف الاشتراكات الخاصة بك وابدأ في جني الأموال.",
  gym_creating:
    "من خلال إنشاء صالة الألعاب الرياضية الخاصة بك ، يمكنك إضافة موقع الصالة الرياضية وساعات العمل والوصف و الاشتراكات حتى يتمكن المستخدمون من رؤيته عبر الإنترنت من خلال تطبيق الهاتف المحمول",
  gym_Grow: "زود مصادر دخلك٠",
  gym_Safe: "مدفوعات آمنة و مضمونة",
  gym_Managing:
    "Gymme هو نظام الإدارة الوحيد الذي ستحتاجه لإدارة الصالة الرياضية الخاصة بك.",
  gym_dashboard:
    "ستتعامل لوحة تحكم Gymme مع كل قسم في صالة الألعاب الرياضية الخاصة بك بدءا من الأعضاء إلى البار و الأجهزة والموظفين وبالطبع إحصائيات حول الأرباح والمصاريف وأكثر من ذلك بكثير !.",
  gym_Daily: "احصائيات يومية عن الأعضاء والبار والمصاريف والأرباح ",
  gym_Bar: "إدارة البار ، المخزن ، الطلبات ، الدفع بالأجل.",
  gym_Employess:
    "إدارة الموظفين ، الحضور و الأنصراف ، المرتبات ، الأجازات ، المبيعات المستهدفة ، و تنظيم المدرب الخاص",
  gym_team: "تطبيق فريق Gymme",
  gym_Login:
    "قم بتسجيل الدخول باستخدام اسم المستخدم وكلمة المرور الخاصين بالموظف ، وبذلك تكون جاهزًا!",
  gym_app:
    "تطبيق Gymme Team هو تطبيق لكل موظف في صالة الألعاب الرياضية ، وسيساعد فريق المبيعات على إدارة هدفهم ومكالماتهم وحضورهم ، كما سيساعد المدربين على تتبع تقدم عملائهم وأكثر من ذلك بكثير.",
  Features: "مميزات التطبيق",
  gym_Features: "مميزات لوحة التحكم",
  Features_description:
    "Gymme هو التطبيق الوحيد الذي تحتاجه لتتبع أهدافك من أجل صحة أفضل.",
  Features_management: "Gymme هو نظام الإدارة الوحيد الذي تحتاجه لتتبع أهدافك.",
  mobile_title:
    "احصل على جميع تمارينك و معلومات صالة الألعاب الرياضية الخاصة بك و مؤشر كتلة الجسم في مكان واحد.",
  mobile_desription:
    "Gymme متاح الآن للتنزيل من كل من App Store و Google Play Store.",
  downApp: "متجر أبل",
  downGoogle: "متجر جوجل",
  mobile_do: "ماذا أفعل!",
  mobile_Download: "قم بتنزيل التطبيق وإنشاء بروفايلك الخاص ودعنا نتعاون معًا",
  mobile_subscription:
    "يمكن لعضو Gymme استكشاف الصالات الرياضية القريبة منه والاشتراك بها والاطلاع على تفاصيل اشتراكه.",
  mobile_track: "يمكن لعضو Gymme إضافة تمارين وجدولتها لتتبع هدفه.",
  mobile_healthy:
    "يمكن لعضو Gymme حساب مؤشر كتلة الجسم لتتبع أهدافه٫ لأسلوب حياة صحي أفضل.",
  contact_title: "تواصل معنا",
  contact_description:
    "لديك أي أسئلة؟ تواصل معنا عن طريق نموذج الاتصال الخاص بنا وسنعاود الاتصال بك في أسرع وقت قبل ان تنتهي من تمارينك على تطبيق Gymme.",
  contact_Talk: "دعونا نتحدث",
  contact_Hello: "اهلا اهلا اهلا!",
  contact_friend: "هل أنت صديق Gymme ، إذا لم يكن كذلك فلنكن",
  contact_coffee:
    "سواء كنت تريد أن تقول مرحبًا ، أو تخبرنا بشيء ما ، أو ربما لديك فكرة رائعة عن الصالات الرياضية ، سنكون سعداء بتناول فنجان من القهوة.",
  contact_button: "كن صديقا",
  contact_Community: "انضم إلى مجتمعنا",
  contact_Address: "العنوان",
  contact_Address_description: "أول القاهرة الجديدة ، محافظة القاهرة ، مصر",
  contact_Phone: "رقم الهاتف",
  contact_E_mail: "البريد الإلكتروني",
  contact_Name: "الاسم",
  contact_subject: "عنوان الرسالة",
  contact_Message: "الرساله",
  contact_Send: "ارسال رساله",
  career_title: "انضم إلى فريقنا",
  career_description:
    "انضم إلى فريقنا للمساعدة في تشكيل مستقبل صناعة اللياقة البدنية.",
  career_button: "دعونا نتعاون",
  career_Career: "النمو الوظيفي",
  career_Career_description:
    "لا تقيد نفسك. كثير من الناس يقيدون أنفسهم بما يعتقدون أنه يمكنهم القيام به. يمكنك الذهاب إلى أبعد ما يسمح لك عقلك به. ما تؤمنون به معًا يمكننا تحقيقه.",
  career_Work: "العمل من أي مكان",
  career_Work_description:
    "العمل الجماعي هو القدرة على العمل معًا لتحقيق رؤية مشتركة ، بغض النظر عن المكان الذي نقوم فيه بذلك.",
  career_Salary: "بيئة عمل ودية",
  career_Salary_description:
    "يمكن لبيئة العمل الودية والدافئة أن تحدث فرقًا كبيرًا للموظف. فالعمل في مكان يشعر فيه بالترحيب والاحترام يتيح لهم التركيز فقط على عملهم ويحفزهم على إنتاج عمل جيد.",
  career_Flexible: "ساعات عمل مرنة",
  career_Flexible_description:
    "نولي مزيدًا من الاهتمام لأهمية التوازن بين العمل والحياة ، وسيصبح المزيد والمزيد من الأشخاص في وضع أفضل لاتخاذ القرار والتصرف بشكل أكثر شمولية بشأن ما هو مهم بالنسبة لهم.",
  career_Job: "المناصب الوظيفية",
  career_Job_description:
    "نحن نبحث دائمًا عن أشخاص رائعين للانضمام إلى فريقنا. ألق نظرة على فتحاتنا الحالية.",
  about_title: "مرحبًا! هذا Gymme",
  about_Team: "دعونا نتعاون",
  about_Mission: "مهمتنا",
  about_Values: "رسالتنا",
  about_Mission_description: "أخذ صناعة اللياقة إلى مستوى جديد تمامًا.",
  about_Values_description: "حافظ على كل شيء بسيط وصحي.",
  about_solutions: "نقدم الحلول لجعل الحياة أسهل وأكثر صحة ومتعة.",
  about_platform: "Gymme عبارة عن منصة مصممة خصيصا لأصحاب الجيمات واعضاءها.",
  about_helping:
    "نحن نساعد أصحاب الصالات الرياضية على إدارة المصروفات والتحكم فيها وخفضها وزيادة مصادر الإيرادات. نحن نساعد الرياضيين على الحفاظ على لياقتهم البدنية وصحتهم من خلال تسهيل انضمامهم إلى صالة الألعاب الرياضية أو أي نشاط رياضي",
  about_vision:
    "لدينا رؤية و وجهة لنقل صناعة اللياقة البدنية إلى مستوى آخر وجعلها أسهل لأصحاب الصالات والأفراد.",
  about_Gymme: "بواسطه Gymme",
  about_dreaming:
    "نحن نحلم بتغيير صناعة اللياقة البدنية إلى الأفضل ، ولن نفعل ذلك بمفردنا ، لذا فلنعمل كفريق واحد.",
  home_title: "من نحن؟",
  home_Gymme: "Gymme عبارة عن منصة مصممة خصيصا لأصحاب الجيمات والرياضيين.",
  home_vision:
    "تمتلك Gymme رؤية ووجهة لنقل صناعة اللياقة إلى مستوى آخر وتسهيل الأمر على أصحاب الصالات والأفراد.",
  home_helping:
    "نحن نساعد أصحاب الصالات الرياضية على إدارة المصروفات والتحكم فيها وخفضها وزيادة مصادر الإيرادات. نحن نساعد الرياضيين على الحفاظ على لياقتهم البدنية وصحتهم من خلال تسهيل انضمامهم إلى صالة الألعاب الرياضية أو أي نشاط رياضي.",
  home_Community: "انضم إلى مجتمعنا",
  home_business:
    "نحن نحلم بتغيير صناعة اللياقة البدنية إلى الأفضل ، ولن نفعل ذلك بمفردنا ، لذا فلنعمل كفريق واحد.",
  home_swiper_one_title: "دعونا نتعاون.",
  home_swiper_one_description:
    "تمتلك Gymme رؤية ووجهة لنقل صناعة اللياقة إلى مستوى آخر وتسهيل الأمر على أصحاب الصالات والأفراد.",
  home_swiper_two_title:
    "الخطوة التالية نحو تغيير صناعة اللياقة البدنية للأفراد.",
  home_swiper_two_description:
    "Gymme هو صديقك الرياضي الجديد٫ قم بتنزيل التطبيق الآن.",
  home_swiper_three_title:
    "الخطوة التالية نحو تغيير صناعة اللياقة البدنية للصالات الرياضية.",
  home_swiper_three_description:
    "سجل صالة الألعاب الرياضية الخاصة بك وابدأ في قبول الدفع عبر الإنترنت من أعضاء الجيم الخاص بك.",
  home_swiper_three_button: "سجل مجانا الآن",
  footer_title:
    "انضم إلى عائلتنا وقم بتسجيل الصالة الرياضية الخاصة بك مجانًا وابدأ في قبول الدفع عبر الإنترنت من أعضائك.",
  footer_Register: "سجل الان",
  footer_rights: "كل الحقوق محفوظة.",
  footer_Find: "تجدنا في",
  footer_Links: "روابط سريعة",
  footer_community: "انضم إلى مجتمعنا",
  footer_Join: "انضم إلى مجموعة Facebook الخاصة بنا ودعنا نتحدث.",
  footer_Join_now: "انضم الان",
  offcanvas_title:
    "تمتلك Gymme رؤية ووجهة لنقل صناعة اللياقة البدنية إلى مستوى آخر وتسهيل الأمر على أصحاب الصالات والأفراد.",
  offcanvas_Contact: "معلومات الاتصال",
  offcanvas_Follow: "تابعنا",
  offcanvas_Sign: "تسجيل الدخول",
  model: "مرحبًا بعودتك",
  model_Fill: "املأ بريدك الإلكتروني وكلمة المرور لتسجيل الدخول.",
  model_Email: "بريد الالكتروني",
  model_Password: "كلمه السر",
  model_Forgot: "هل نسيت كلمة السر؟",
  model_account: "ليس لديك حساب؟",
  model_Sign: "اشتراك",
  Register_owner: "معلومات صاحب الصالة الرياضية",
  Register_information: "معلومات الصالة الرياضية",
  Register_Payment: "الدفع",
  Register_Summary: "ملخص معلومات الاشتراك",
  Register_Confirm_payment: "تأكيد الدفع",
  Register_card: "بطاقات الإئتمان",
  Register_Cash: "نقدي",
  Register_Credit: "رقم الائتمان",
  Register_Number: "الرقم على البطاقة",
  Register_Exp: "انتهاء الصلاحية",
  Register_CVV: "CVV",
  address: "العنوان",
  Register_first_name: "الاسم الأول",
  Register_last_name: "اسم العائلة",
  Register_Password: "كلمه المرور",
  Register_Confirm_Password: "تأكيد كلمة المرور",
  Register_Nike: "سيب ",
  Register_Color: "اللون",
  Register_Black: "اسود",
  Register_Size: "المقاس",
  Register_Subtotal: "المجموع الفرعي",
  Register_Discount: "خصم",
  Register_Total: "المبلغ الإجمالي",
  Register: "سجل الان",
  Branches: "الفروع",
  Member: "عضو",
  Employees: "الموظفين",
  CASH: "نظام المدفوعات",
  ATTENDANCE: "نظام الحضور",
  CommingSoon: "قريبا",
  RegisterDone: "لقد تلقينا طلبك وسنرد قريبًا",
  NoVacancies: "لا يوجد وظائف مطلوبة حاليا",
  Position: "الوظيفة",
  Business: "بيزنس",
  Design: "تصميم",
  Development: "تطوير",
  Engineering: "هندسة",
  Marketing: "تسويق",
  Finance: "حسابات",
  Type: "النوع",
  FullTime: "دوام كامل",
  PartTime: "دوام نصفي",
  Remote: "عن بعد",
  Was: "كانت",
  EGP: "جنيه",
  Save: "وفر",
};
