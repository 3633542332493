import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../../AppContext";
import Config from "../../../Config";

const Membership = ({ yearlyActive, lang }) => {
  const { translate } = useContext(AppContext);
  const t = (k) => translate(k);
  const [memberships, setMemberships] = useState([]);

  const requestUrl = Config.apiBaseUrl + "/gym-membership/gym-membership-data";

  useEffect(() => {
    Config.getHashForPrintToken()
      .then((hash) => {
        return axios.post(`${Config.apiBaseUrl}/print-token`, {
          hash: hash,
          type: "landing",
        });
      })
      .catch((err) => console.log("Hash Generator Error", { ...err }))
      .then((printToken) => {
        axios
          .get(
            `${requestUrl}?limit=4&token=${printToken?.data?.token}&lang=${lang}`
          )
          .then((response) => setMemberships(response?.data?.data))
          .catch((err) => console.log("Api Call Error", { ...err }));
      })
      .catch((err) => console.log("Print Token Error", { ...err }));
  }, [setMemberships, requestUrl, lang]);

  return (
    <div className="row gx-0 gy-6 mt-2">
      {memberships.map((item, index) => (
        <div className={`col-md-6 col-lg-3 ${lang}`} key={index}>
          <div
            className={`pricing card shadow-none ${lang}`}
            style={{ height: "100%" }}
          >
            <div
              className="card-body"
              style={{ height: "100%", position: "relative" }}
            >
              <div className="text-center">
                <img
                  src={item.icon}
                  className={"logo-plan-card"}
                  style={{ width: "70px", height: "45px" }}
                  alt=""
                />
              </div>
              <h4 className="card-title text-center">{item?.name}</h4>
              <div className="prices text-dark">
                <div className="price price-show">
                  <span className="price-value" data-aos="fade-in">
                    {yearlyActive ? item.price : item.yearly_price}
                  </span>{" "}
                  <span className="price-duration">
                    {yearlyActive ? t("gym_Month") : t("gym_Year")}
                  </span>
                </div>
              </div>
              {item.old_price ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: "14px",
                  }}
                >
                  <span>{t("Was")}</span>&nbsp;
                  <span style={{ textDecorationLine: "line-through" }}>
                    {yearlyActive ? item.old_price : item.yearly_old_price}{" "}
                    {t("EGP")}
                  </span>
                  &nbsp;
                  <span> {t("Save")}</span>&nbsp;
                  <span style={{fontWeight: 'bold', fontSize: '15px'}}>
                    {yearlyActive
                      ? item.old_price - item.price
                      : item.yearly_old_price - item.yearly_price}{" "}
                    {t("EGP")}
                  </span>
                  &nbsp;
                </div>
              ) : (
                <div>&nbsp;</div>
              )}
              <ul className="icon-list bullet-bg bullet-soft-primary mt-8 mb-9">
                <li className={`${lang}`}>
                  <i className="uil uil-check"></i>
                  <span>{item.short_description}</span>
                </li>
                <li className="">
                  <i className="uil uil-check"></i>
                  <span>
                    {item.max_number_of_branches} {t("Branches")}
                  </span>
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span>
                    {item.max_number_of_members} {t("Member")}
                  </span>
                </li>
                <li>
                  <i className="uil uil-check"></i>
                  <span>
                    {item.max_number_of_employees} {t("Employees")}
                  </span>
                </li>
                {item?.features?.map?.((feature, fIndx) => (
                  <li key={fIndx}>
                    <i className="uil uil-check"></i>
                    <span> {feature?.name} </span>
                  </li>
                ))}
              </ul>
              <Link
                to="/Register"
                className="btn btn-outline-primary rounded-pill"
                style={{ position: "absolute", bottom: "2rem" }}
                onClick={(e) => {
                  const _item = {
                    ...item,
                    subsc_type: yearlyActive ? "month" : "year",
                  };
                  localStorage.setItem("subscription", JSON.stringify(_item));
                }}
              >
                {t("career_button")}
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Membership;
