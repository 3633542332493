import './index.css'

const Spinner = () => {
    return (
        <div className="shared-spinner">
            <div className="shared-spinner-dot"></div>
            <div className="shared-spinner-dot"></div>
            <div className="shared-spinner-dot"></div>
            <div className="shared-spinner-dot"></div>
            <div className="shared-spinner-dot"></div>
            <div className="shared-spinner-dot"></div>
        </div>
    )
}

export default Spinner