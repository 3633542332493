import { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../AppContext";
import Config from "../../Config";

const Info = () => {
    const {translate ,systemLang ,changeLanguage} = useContext(AppContext)
    const t = k => translate(k)
    
    return (
      <div
        className="offcanvas offcanvas-end text-inverse"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        tabIndex="-1"
      >
        <div className="offcanvas-header">
          <h3 className="text-white fs-30 mb-0">Gymme</h3>
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body pb-6">
          <div className="widget mb-8">
            <p>{t("offcanvas_title")}</p>
          </div>
          <div className="widget mb-8">
            <h4 className="widget-title text-white mb-3">
              {t("offcanvas_Contact")}
            </h4>
            {Config?.emails?.map?.((email, index) => (
              <a key={index} href={`mailto:${email}`} className="d-block">
                {email}
              </a>
            ))}
            {Config?.phones?.map?.((phone, index) => (
              <a key={index} href={`tel:${phone}`} className="d-block">
                {phone}
              </a>
            ))}
          </div>
          <div className="widget mb-8">
            <h4 className="widget-title text-white mb-3">
              {t("footer_Links")}
            </h4>
            <ul className="list-unstyled">
              <li>
                <Link to="/Gyms">{t("Gyms")}</Link>
              </li>
              <li>
                <Link to="/Mobile">{t("Mobile")}</Link>
              </li>
              <li>
                <Link to="/About">{t("about_nav")}</Link>
              </li>
              <li>
                <Link to="/Privacy">{t("Privacy")}</Link>
              </li>
              <li>
                <Link to="/Terms">{t("terms")}</Link>
              </li>
              <li>
                <select
                  value={systemLang}
                  onChange={(e) => {
                    changeLanguage(systemLang === "en" ? "ar" : "en");
                  }}
                  className="nav-item dropdown language-select text-uppercase text-dark p-0 nav-link d-header"
                >
                  <option value="en" className="nav-item">
                    <Link className="dropdown-item" to="#!">
                      {t("En")}
                    </Link>
                  </option>
                  <option value="ar" className="nav-item">
                    <Link className="dropdown-item" to="#!">
                      {t("Ar")}
                    </Link>
                  </option>
                </select>
              </li>
            </ul>
          </div>
          <div className="widget">
            <h4 className="widget-title text-white mb-3">
              {t("offcanvas_Follow")}
            </h4>
            <nav className="nav social social-white">
              <a
                rel="noreferrer"
                target="_blank"
                href={Config?.socialMediaLinks?.twitter}
              >
                <i className="uil uil-twitter"></i>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={Config?.socialMediaLinks?.facebook}
              >
                <i className="uil uil-facebook-f"></i>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={Config?.socialMediaLinks?.linkedin}
              >
                <i className="uil uil-linkedin"></i>
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={Config?.socialMediaLinks?.insta}
              >
                <i className="uil uil-instagram"></i>
              </a>
            </nav>
          </div>
        </div>
      </div>
    );
}

export default Info