const Jobs = [
  {
    id: 1,
    avatar: "SD",
    class: "avatar bg-red text-white w-9 h-9 fs-17 me-3",
    name_en: "Senior Graphic Designer",
    name_ar:
      "ثانيًا ، اكتب اسم الصالة الرياضية ورقم الهاتف والبريد الإلكتروني. ثم اختر اللغات المفضلة لديك",
    time_en: "Full time",
    time_ar:
      "ثالثًا ، اختر نوع الصالة الرياضية الخاصة بك. لديك 3 اختيارات من الذكور والإناث والمختلطة",
    location_en: "San Francisco, US",
    location_ar: "رابعًا ، إذا كان لديك شريط ، فاختر ما تقدمه",
    category: "Design",
    Description:
      "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget porta ac consectetur vestibulum.",
    class_jop: "badge bg-pale-blue text-blue rounded py-1 mb-2",
  },
  {
    id: 2,
    avatar: "UX",
    class: "avatar bg-green text-white w-9 h-9 fs-17 me-3",
    name_en: "UI/UX Designer",
    name_ar:
      "ثانيًا ، اكتب اسم الصالة الرياضية ورقم الهاتف والبريد الإلكتروني. ثم اختر اللغات المفضلة لديك",
    time_en: "Remote",
    time_ar:
      "ثالثًا ، اختر نوع الصالة الرياضية الخاصة بك. لديك 3 اختيارات من الذكور والإناث والمختلطة",
    location_en: "Anywhere",
    location_ar: "رابعًا ، إذا كان لديك شريط ، فاختر ما تقدمه",
    category: "Design",
    Description:
      "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget porta ac consectetur vestibulum.",
    class_jop: "badge bg-pale-aqua text-aqua rounded py-1 mb-2",
  },
  {
    id: 3,
    avatar: "AN",
    class: "avatar bg-yellow text-white w-9 h-9 fs-17 me-3",
    name_en: "Multimedia Artist & Animator",
    name_ar: "ثانيًا ",
    time_en: "Full time",
    time_ar: "ثالثًا",
    location_en: "Birmingham, UK",
    location_ar: "رابعًا ",
    category: "Design",
    Description:
      "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget porta ac consectetur vestibulum.",
    class_jop: "badge bg-pale-blue text-blue rounded py-1 mb-2",
  },
  {
    id: 4,
    avatar: "FE",
    name_en: "Front End Developer",
    name_ar:
      "ثانيًا ، اكتب اسم الصالة الرياضية ورقم الهاتف والبريد الإلكتروني. ثم اختر اللغات المفضلة لديك",
    time_en: "Part time",
    time_ar:
      "ثالثًا ، اختر نوع الصالة الرياضية الخاصة بك. لديك 3 اختيارات من الذكور والإناث والمختلطة",
    location_en: "Sydney, AU",
    location_ar: "رابعًا ، إذا كان لديك شريط ، فاختر ما تقدمه",
    class: "avatar bg-purple text-white w-9 h-9 fs-17 me-3",
    category: "Development",
    Description:
      "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget porta ac consectetur vestibulum.",
    class_jop: "badge bg-pale-violet text-violet rounded py-1 mb-2",
  },
  {
    id: 5,
    avatar: "MD",
    name_en: "Mobile Developer",
    name_ar:
      "ثانيًا ، اكتب اسم الصالة الرياضية ورقم الهاتف والبريد الإلكتروني. ثم اختر اللغات المفضلة لديك",
    time_en: "Full-time",
    time_ar:
      "ثالثًا ، اختر نوع الصالة الرياضية الخاصة بك. لديك 3 اختيارات من الذكور والإناث والمختلطة",
    location_en: "San Francisco, US",
    location_ar: "رابعًا ، إذا كان لديك شريط ، فاختر ما تقدمه",
    class: "avatar bg-orange text-white w-9 h-9 fs-17 me-3",
    category: "Development",
    Description:
      "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget porta ac consectetur vestibulum.",
    class_jop: "badge bg-pale-blue text-blue rounded py-1 mb-2",
  },
  {
    id: 6,
    avatar: "NT",
    name_en: ".NET Developer",
    name_ar:
      "ثانيًا ، اكتب اسم الصالة الرياضية ورقم الهاتف والبريد الإلكتروني. ثم اختر اللغات المفضلة لديك",
    time_en: "Full time",
    time_ar:
      "ثالثًا ، اختر نوع الصالة الرياضية الخاصة بك. لديك 3 اختيارات من الذكور والإناث والمختلطة",
    location_en: "San Francisco, US",
    location_ar: "رابعًا ، إذا كان لديك شريط ، فاختر ما تقدمه",
    class: "avatar bg-pink text-white w-9 h-9 fs-17 me-3",
    category: "Development",
    Description:
      "Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget porta ac consectetur vestibulum.",
    class_jop: "badge bg-pale-blue text-blue rounded py-1 mb-2",
  },
];

export default Jobs;
