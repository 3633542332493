import "../../assets/css/plugins.css";
import "../../assets/css/style.css";
import "../../assets/css/colors/red.css";
import sa1Img from "../../assets/img/imags/sa1.jpg";
import sa1Img2x from "../../assets/img/imags/sa1@2x.jpg";
import sa2Img from "../../assets/img/imags/sa2.png";
import sa2Img2x from "../../assets/img/imags/sa2@2x.png";
import sa3Img from "../../assets/img/imags/sa3.png";
import sa3Img2x from "../../assets/img/imags/sa3@2x.png";
import sa4Img from "../../assets/img/imags/sa4.png";
import sa4Img2x from "../../assets/img/imags/sa4@2x.png";
import sa13Img from "../../assets/img/imags/sa13.png";
import sa13Img2x from "../../assets/img/imags/sa13@2x.png";
import sa14Img from "../../assets/img/imags/sa14.png";
import sa14Img2x from "../../assets/img/imags/sa14@2x.png";
import sa15Img from "../../assets/img/imags/sa15.png";
import sa15Img2x from "../../assets/img/imags/sa15@2x.png";
import DevicesImg from "../../assets/img/imags/device2.png";
import DevicesImg2x from "../../assets/img/imags/device2@2x.png";
import Features from "../../DATA/FEATURES";
import question from "../../DATA/question";
import { useContext, useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import Navbar from "../../Navbar/Navbar";
import AOS from "aos";
import "../../../../node_modules/aos/dist/aos.css";
import axios from "axios";
import Config from "../../../Config";
import PresentationImages from "./PresentationImages";
import Membership from "./Membership";
import AppContext from "../../../AppContext";

function Gyms(props) {
  const { translate, systemLang } = useContext(AppContext);
  const t = (k) => translate(k);

  AOS.init({
    once: true,
  });

  const [isActive, setActive] = useState(true);
  const handleToggle = () => {
    setActive(!isActive);
  };

  const [memberships, setMemberships] = useState([]);
  const requestUrl = Config.apiBaseUrl + "/gym-membership/gym-membership-data";

  const getMemberships = async () => {
    const hash = await Config.getHashForPrintToken().catch((err) =>
      console.log({ ...err })
    );
    const printToken = await axios
      .post(`${Config.apiBaseUrl}/print-token`, {
        hash: hash,
        type: "landing",
      })
      .catch((err) => console.log({ ...err }));
    const responseData = await axios
      .get(`${requestUrl}?limit=4&token=${printToken?.data?.token}`)
      .catch((err) => console.log({ ...err }));
    setMemberships(responseData.data.data);
    return;
  };

  useEffect(() => {
    getMemberships();
  }, [setMemberships]);

  return (
    <>
      <Navbar />
      <div className={`wrapper bg-soft-primary ${systemLang}`}>
        <div className="container pt-10 pb-15 pt-md-14 pb-md-20 text-center">
          <div className="row">
            <div
              className="col-md-10 col-lg-8 col-xl-8 col-xxl-6 mx-auto mb-13"
              data-aos="fade-down"
              data-aos-duration="1200"
            >
              <h1 className="display-1 mb-4">{t("gym_title")}</h1>
              <p className="lead fs-lg px-xl-12 px-xxl-6 mb-7">
                {" "}
                {t("gym_description")}{" "}
              </p>
              <div
                className="d-flex justify-content-center"
                data-aos="fade-down"
                data-group="page-title-buttons"
                data-aos-duration="1200"
              >
                {/* <span>
                  <a className="btn btn-outline-primary rounded mx-1" href="#pricing">
                    {t("gym_button_Started")}
                  </a>
                </span> */}
                <span>
                  <a href="#pricing" className="btn btn-primary rounded mx-1">
                    {t("gym_button_Register")}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`wrapper bg-light ${systemLang}`}>
        <div className="container pb-14 pb-md-16 mb-lg-1 mb-xl-3">
          <div className="row gx-0 mb-16 mb-mb-20">
            <div className="col-9 col-sm-10 col-lg-9 mx-auto mt-n15 mt-md-n20">
              <img
                className="img-fluid mx-auto rounded shadow-lg"
                data-aos="fade-up"
                data-aos-duration="1200"
                src={sa1Img}
                srcSet={sa1Img2x}
                alt=""
              />
              <img
                className="position-absolute rounded shadow-lg"
                data-aos="fade-left"
                data-aos-duration="1200"
                src={sa2Img}
                srcSet={sa2Img2x}
                style={{
                  top: "20%",
                  right: "-10%",
                  maxWidth: "30%",
                  height: "auto",
                }}
                alt=""
              />
              <img
                className="position-absolute rounded shadow-lg"
                data-aos="fade-right"
                data-aos-duration="1200"
                src={sa3Img}
                srcSet={sa3Img2x}
                style={{
                  top: "10%",
                  left: "-10%",
                  maxWidth: "30%",
                  height: "auto",
                }}
                alt=""
              />
              <img
                className="position-absolute rounded shadow-lg"
                data-aos="fade-right"
                data-aos-duration="2000"
                src={sa4Img}
                srcSet={sa4Img2x}
                style={{
                  bottom: "10%",
                  left: "-13%",
                  maxWidth: "30%",
                  height: "auto",
                }}
                alt=""
              />
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center mb-14 mb-md-18">
            <PresentationImages />
            <div className="col-lg-6">
              <h3 className="display-4 mb-5">{t("gym_Create")}</h3>
              <p className="mb-5">{t("gym_creating")}</p>
              <div className="row gy-3">
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-green mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>{t("gym_Grow")}</span>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-green mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>{t("gym_Safe")}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-6 position-relative">
              <div
                className="shape rounded bg-pale-yellow relax d-block"
                data-relax-speed="0"
                style={{
                  top: "50%",
                  left: "50%",
                  width: "50%",
                  height: "60%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "0",
                }}
              ></div>
              <div className="row gx-md-5 gy-5 position-relative align-items-center">
                <div className="col-6">
                  <img
                    className="img-fluid rounded shadow-lg d-flex ms-auto"
                    data-aos="fade-in"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    src={sa13Img}
                    srcSet={sa13Img2x}
                    alt=""
                  />
                </div>
                <div className="col-6">
                  <img
                    className="img-fluid rounded shadow-lg mb-5"
                    data-aos="fade-in"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="800"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    src={sa14Img}
                    srcSet={sa14Img2x}
                    alt=""
                  />
                  <img
                    className="img-fluid rounded shadow-lg d-flex col-10"
                    data-aos="fade-in"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1200"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    src={sa15Img}
                    srcSet={sa15Img2x}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h3 className="display-4 mb-5">{t("gym_Managing")}</h3>
              <p className="mb-5">{t("gym_dashboard")}</p>
              <div className="row gy-3">
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-yellow mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>{t("gym_Daily")}</span>
                    </li>
                    <li className="mt-3">
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>{t("gym_Bar")}</span>
                    </li>
                  </ul>
                </div>
                <div className="col-xl-6">
                  <ul className="icon-list bullet-bg bullet-soft-yellow mb-0">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>{t("gym_Employess")}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper bg-light ">
        <div className="container py-14 py-md-17">
          <div className="row text-center mt-xl-12">
            <div className="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                {t("gym_Features")}
              </h2>
              <h3
                className={`${
                  systemLang === "ar" ? "ar" : "en"
                } display-4 mb-9 px-xxl-11`}
              >
                {t("Features_management")}
              </h3>
            </div>
          </div>

          <div className="row gx-lg-8 gx-xl-12 gy-8 mb-14 mb-md-17">
            {Features.map((x, index) => (
              <div className={`col-md-6 col-lg-4 ${systemLang}`} key={index}>
                <div
                  className="d-flex flex-row"
                  data-aos="slide-up"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                >
                  <div>
                    {/* <img
                      src={x.src}
                      className="icon-svg icon-svg-sm text-aqua me-4"
                      alt=""
                    /> */}
                    {x.src}
                  </div>
                  <div>
                    <h4 className="mb-1">
                      {systemLang === "ar" ? x.title_ar : x.title}
                    </h4>
                    <p className="mb-0">
                      {systemLang === "ar" ? x.description_ar : x.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className={`row gx-lg-8 gx-xl-10 mb-lg-10 mb-xl-12 align-items-center ${systemLang}`}
          >
            <div className="col-lg-6">
              <figure>
                <img
                  src={DevicesImg}
                  srcSet={DevicesImg2x}
                  data-aos="fade-in"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="100"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  alt=""
                />
              </figure>
            </div>
            <div className="col-lg-6">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                {t("gym_team")}
              </h2>
              <h3 className="display-4 mb-5">{t("gym_Login")}</h3>
              <p className="mb-8">{t("gym_app")}</p>
              <div
                className="d-flex justify-content-center justify-content-lg-start"
                data-aos="fade-down"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="500"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
              >
                <span>
                  <a
                    className="btn btn-outline-primary btn-icon btn-icon-start rounded me"
                    rel="noreferrer"
                    href={Config.employeeIosApp}
                    target="_blank"
                  >
                    <i className="uil uil-apple"></i> {t("downApp")}
                  </a>
                </span>
                <span>
                  <a
                    className="btn btn-outline-primary btn-icon btn-icon-start rounded hover-primary"
                    rel="noreferrer"
                    href={Config.employeeAndroidApp}
                    target="_blank"
                  >
                    <i className="uil uil-google-play"></i> {t("downGoogle")}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper bg-light" id="pricing">
        <div className="container py-14 py-md-16">
          <h2 className="display-5 mb-7 text-center">{t("gym_Pricing")}</h2>
          <div className="pricing-wrapper">
            <div className="pricing-switcher-wrapper switcher ">
              <p className="mb-0 pe-3">{t("gym_Monthly")}</p>
              <div className="form-check form-switch">
                <input
                  className="form-check-input "
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  onClick={handleToggle}
                />
              </div>
              <p className="mb-0 ps-3">
                {t("gym_Yearly")}{" "}
                <span className="text-red en">({t("gym_Save")})</span>
              </p>
            </div>
            <Membership
              lang={systemLang === "ar" ? "ar" : "en"}
              yearlyActive={isActive}
            />
          </div>
        </div>
      </div>
      <div className={`wrapper pt-15 bg-soft-primary ${systemLang}`}>
        <div className="container py-14 pt-md-16 pt-lg-0 pb-md-16">
          <div className="row">
            <div className="col-xl-10 mx-auto">
              <h2 className="fs-15 text-uppercase text-muted mb-3 text-center">
                {t("gym_FAQ")}
              </h2>
              <h3 className="display-4 mb-10 px-lg-12 px-xl-15 text-center">
                {t("gym_question")}
              </h3>
              <div id="accordion-1" className="accordion-wrapper">
                <div
                  className="row"
                  // data-cue="fadeIn"
                  data-aos="fade-in"
                  data-aos-offset="200"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                >
                  {question.map((x, index) => (
                    <div className="col-md-6 mb-0" key={index}>
                      <div className={`card accordion-item ${systemLang}`}>
                        <div
                          className={`card-header ${systemLang}`}
                          id={x.data_bs_target}
                        >
                          <button
                            className={`collapsed ${systemLang}`}
                            data-bs-toggle="collapse"
                            data-bs-target={"#" + x.aria_labelled}
                            aria-expanded="false"
                            aria-controls={x.aria_labelled}
                          >
                            {systemLang === "ar" ? x.question_ar : x.question}
                          </button>
                        </div>
                        <div
                          id={x.aria_labelled}
                          className="collapse"
                          aria-labelledby={x.data_bs_target}
                          data-bs-target="#accordion-1"
                        >
                          <div className="card-body">
                            <p>
                              {systemLang === "ar" ? x.answer_ar : x.answer}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Gyms;
