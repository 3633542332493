import sa9Img from "../../assets/img/imags/sa9@2x.png";
import sa10Img from "../../assets/img/imags/sa10@2x.png";
import sa11Img from "../../assets/img/imags/sa11@2x.png";
import sa12Img from "../../assets/img/imags/sa12@2x.png";

const styles = {
  div_one_style: {
    top: "50%",
    left: "50%",
    width: "50%",
    height: "60%",
    transform: "translate(-50%,-50%)",
    zIndex: "0",
  },
};

const PresentationImages = () => {
  return (
    <div className="col-lg-6 position-relative order-lg-2">
      <div
        className="shape rounded bg-pale-green relax d-block"
        data-relax-speed="0"
        style={styles.div_one_style}
      ></div>
      <div className="row gx-md-5 gy-5 position-relative">
        <div className="col-5">
          <img
            className="img-fluid rounded shadow-lg my-5 d-flex ms-auto"
            data-aos="fade-in"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="600"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            src={sa9Img}
            alt=""
          />
          <img
            className="img-fluid rounded shadow-lg d-flex col-10 ms-auto"
            data-aos="fade-in"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            src={sa10Img}
            alt=""
          />
        </div>
        <div className="col-7">
          <img
            className="img-fluid rounded shadow-lg mb-5"
            data-aos="fade-in"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1800"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            src={sa11Img}
            alt=""
          />
          <img
            className="img-fluid rounded shadow-lg d-flex col-11"
            data-aos="fade-in"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="2500"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            src={sa12Img}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default PresentationImages;
