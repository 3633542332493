export const TRANSLATIONS_en = {
  home: "Home",
  Gyms: "Gyms",
  Careers: "Careers",
  Mobile: "Mobile App",
  contact: "Contact",
  terms: "Terms of Use",
  Privacy: "Privacy Policy",
  about_nav: "About",
  En: "English 🇺🇸",
  Ar: "العربية 🇪🇬",
  gym_title: "Managing your Gym has never been easier",
  gym_description: "Try it now for free and start Collecting money.",
  gym_button_Started: "Get Started",
  gym_button_Register: "Register For Free",
  gym_Pricing: "Our Pricing",
  gym_Monthly: "Monthly",
  gym_Yearly: "Yearly",
  gym_Month: "EGP/Mo",
  gym_Year: "EGP/Ye",
  gym_Save: "Save",
  gym_FAQ: "FAQ",
  gym_question:
    "If you don't see an answer to your question, you can send us an email from our contact form.",
  gym_Create:
    "Create your gym, Add your subscription plans and start make money.",
  gym_creating:
    "by creating your gym you can add your gym location, opening hours, description and subscription plans so users can see it online through the mobile application",
  gym_Grow: "Grow your revenue streams.",
  gym_Safe: "Safe and secured payment",
  gym_Managing:
    "Gymme is the only management system you will need to manage your Gym.",
  gym_dashboard:
    "Gymme dashboard will handles every area of your gym from the members to the bar, equipments, employees and of course statistics about the cashflow from earnings & expenses and much more!.",
  gym_Daily: "Daily statistics about members, bar, expenses and earnings",
  gym_Bar: "Bar management, stock, orders, leftover.",
  gym_Employess:
    "Employees management, attendance, salary, days off, sales target, and pt for coaches",
  gym_team: "Gymme team app",
  gym_Login:
    "Login with your Employees Username and password, and you're all set!",
  gym_app:
    "Gymme Team app is a mobile app for every Employees at gym which will help sales team to manage their target, calls, and attendance, in addition, it will help coaches to track the progress of their clients them and much more.",
  Features: "App Features",
  gym_Features: "Dashboard Features",
  Features_description:
    "Gymme is the only app you need to track your goals for better health.",
  Features_management:
    "Gymme is the only management system you need to track your goals.",
  mobile_title: " Get all of your exercises, gym info and BMI in one place.",
  mobile_desription:
    "Gymme is now available to download from both the App Store and Google Play Store.",
  downApp: "App Store",
  downGoogle: "Play store",
  mobile_do: "What to do!",
  mobile_Download: "Download the app, create your profile and let's team up",
  mobile_subscription:
    "Gymme member can explore nearby gyms, subscribe and see his gym subscription details.",
  mobile_track:
    "Gymme member can add exercises and schedule them to keep track of his goal.",
  mobile_healthy:
    "Gymme member can calculate his BMI to track his goals for a better healthy life style.",
  contact_title: "Get in Touch",
  contact_description:
    "Have any questions? Reach out to us from our contact form and we will get back to you Faster than you finish your Exercises on Gymme.",
  contact_Talk: "Let’s Talk",
  contact_Hello: "Hello Hello Hello!",
  contact_friend: "Are you a friend of gymme, if not then let's be",
  contact_coffee:
    "whether you want to say hi, or telling us something, or maybe you have a great idea for gymme, we'll be glad to have a cup of coffee.",
  contact_button: "Be A Friend",
  contact_Community: "Join Our Community",
  contact_Address: "Address",
  contact_Address_description: "First New Cairo, Cairo Governorate, Egypt",
  contact_Phone: "Phone",
  contact_E_mail: "E-mail",
  contact_Name: "Name",
  contact_subject: "Subject",
  contact_Message: "Message",
  contact_Send: "Send message",
  career_title: "Join Our Team",
  career_description:
    "Join our team to help shape the future of the fitness industry.",
  career_button: "Let's Team Up",
  career_Career: "Career Growth",
  career_Career_description:
    "Don't limit yourself. Many people limit themselves to what they think they can do. You can go as far as your mind lets you. What you believe together we can achieve.",
  career_Work: "Work From Anywhere",
  career_Work_description:
    "Teamwork is the ability to work together toward a common vision, no matter where we do that.",
  career_Salary: "Friendly work environment",
  career_Salary_description:
    "A friendly and warm working environment can make all the difference to an employee. Working in a place where they feel welcome and respected allows them to just focus on their work and motivates them to produce good work.",
  career_Flexible: "Flexible Hours",
  career_Flexible_description:
    "we give more attention to the importance of work-life balance, more and more people will be in a better position to decide and act more holistically on what’s important to them.",
  career_Job: "Job Positions",
  career_Job_description:
    "We’re always searching for amazing people to join our team. Take a look at our current openings.",
  about_title: "Hello! This is Gymme",
  about_Team: "Let's Team Up",
  about_Mission: "Our Mission",
  about_Values: "Our Values",
  about_Mission_description:
    "Taking the fitness industry to a whole new level.",
  about_Values_description: "Keep everything simple & healthy.",
  about_solutions:
    "We bring solutions to make life easier, Healthier and more fun.",
  about_platform: "Gymme is fitness platform built for gym owners and members.",
  about_helping:
    "we are helping gym owners to manage, control, deduct and reduce the expenses and increase revenue streams of their gyms. we are helping gym members to keep fit, healthy by make it easier for them to join gym or any sport activity",
  about_vision:
    "we have a vision and a destination to take the fitness industry to another level and make it easier for businesses and individuals.",
  about_Gymme: "by Gymme",
  about_dreaming:
    "We are dreaming of changing the fitness industry for good, we won't do it alone, so Let's Team Up .",
  home_title: "Who Are We?",
  home_Gymme: "Gymme is fitness platform built for gym owners and gym members.",
  home_vision:
    "we have a vision and a destination to take the fitness industry to another level and make it easier for businesses and individuals.",
  home_helping:
    "we are helping gym owners to manage, control, deduct and reduce the expenses and increase revenue streams of their gyms. we are helping athletes to keep fit, healthy by make it easier for them to join gym or any sport activity.",
  home_Community: "Join Our Community",
  home_business:
    "We are dreaming of changing the fitness industry for good, we won't do it alone, so Let's Team Up .",
  home_swiper_one_title: "Let's Team Up .",
  home_swiper_one_description:
    "Gymme has a vision and a destination to take the fitness industry to another level and make it easier for businesses and individuals.",
  home_swiper_two_title:
    "The next step towards changing the fitness industry for individuals.",
  home_swiper_two_description:
    "Gymme is your new Gym Budd, Download the app now!",
  home_swiper_three_title:
    "The next step towards changing the fitness industry for Gyms.",
  home_swiper_three_description:
    "Register your gym and start accept payment from your Gym members.",
  home_swiper_three_button: "Register For Free Now",
  footer_title:
    "Join our Family and Register your gym for free and start accept payment from your members.",
  footer_Register: "Register Now",
  footer_rights: "All rights reserved.",
  footer_Find: "Find us at",
  footer_Links: "Quick Links",
  footer_community: "Join our community",
  footer_Join: "Join our Facebook group and let's have a talk.",
  footer_Join_now: "Join Now",
  offcanvas_title:
    "Gymme has a vision and a destination to take the fitness industry to another level and make it easier for businesses and individuals.",
  offcanvas_Contact: "Contact Info",
  offcanvas_Follow: "Follow Us",
  offcanvas_Sign: "Sign In",
  model: "Welcome Back",
  model_Fill: "Fill your email and password to sign in.",
  model_Email: "Email",
  model_Password: "Password",
  model_Forgot: "Forgot Password?",
  model_account: "Don't have an account?",
  model_Sign: "Sign up",
  Register_owner: "Gym owner information",
  Register_information: "Gym information",
  Register_Payment: "Payment",
  Register_Summary: "Subscription Summary",
  Register_Confirm_payment: "Confirm Payment",
  Register_card: "Credit card",
  Register_Cash: "Cash",
  Register_Credit: "Credit number",
  Register_Number: "Number On Card",
  Register_Exp: "Exp",
  Register_CVV: "CVV",
  address: "Address",
  Register_first_name: "First name",
  Register_last_name: "Last name",
  Register_Password: "Password",
  Register_Confirm_Password: "Confirm Password",
  Register_Nike: "Nike Air Sneakers",
  Register_Color: "Color",
  Register_Black: "Black",
  Register_Size: "Size",
  Register_Subtotal: "Subtotal",
  Register_Discount: "Discount",
  Register_Total: "Grand Total",
  Register: "Register Now",
  Branches: "Branches",
  Member: "Member",
  Employees: "Employees",
  CASH: "CASH FLOW SYSTEM",
  ATTENDANCE: "ATTENDANCE SYSTEM",
  CommingSoon: "Coming Soon",
  RegisterDone: "We received your request and will reply soon",
  NoVacancies: "No opening vacancies right now",
  Position: "Position",
  Business: "Business",
  Design: "Design",
  Development: "Development",
  Engineering: "Engineering",
  Marketing: "Marketing",
  Finance: "Finance",
  Type: "Type",
  FullTime: "Full-time",
  PartTime: "Part-time",
  Remote: "Remote",
  Was: "Was",
  EGP: "EGP",
  Save: "Save",
};
