const Features = [
  {
    id: 1,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 316.5 409.6"
      >
        <path
          class="lineal-stroke"
          d="M158.2 409.6c-59.4 0-107.5-48.2-107.5-107.5S99 194.6 158.3 194.6s107.5 48.2 107.5 107.5c0 20-5.6 39.6-16.1 56.6-3.4 5.5-10.6 7.1-16 3.7s-7.1-10.6-3.7-16c24.4-39.5 12.2-91.3-27.3-115.8S111.3 218.5 86.8 258s-12.2 91.3 27.3 115.8c22.5 13.9 50.2 16.4 74.8 6.8 6-2.3 12.7.6 15.1 6.6 2.3 6-.6 12.7-6.6 15.1-12.5 4.8-25.8 7.3-39.2 7.3z"
        />
        <path
          class="lineal-fill"
          d="M158.2 206.2c19.9 0 39.4 6.2 55.6 17.8l88.1-152.5c4.6-8 3.7-18-2.2-25l-23-27.1c-4.1-4.9-10.2-7.7-16.6-7.7H56.4c-6.4 0-12.5 2.8-16.6 7.7l-23 27.2c-6 7-6.9 17-2.2 25l88 152.4c16.2-11.6 35.7-17.8 55.6-17.8zm73.4-134l-73.3 127-73.4-127h146.7z"
        />
        <path
          class="lineal-stroke"
          d="M213.8 235.6c-2.4 0-4.8-.8-6.8-2.2-29.2-20.8-68.5-20.8-97.7 0-5.2 3.7-12.5 2.5-16.2-2.7l-.6-.9-88-152.5c-7-12.2-5.7-27.5 3.4-38.3l23-27.2C37.2 4.3 46.6 0 56.4 0h203.7c9.8 0 19.2 4.3 25.5 11.8l23 27.1c9.1 10.8 10.5 26.1 3.4 38.3l-88.1 152.5c-1.6 2.8-4.4 4.9-7.6 5.6-.8.2-1.6.3-2.5.3zM56.4 23.3c-3 0-5.8 1.3-7.8 3.6L25.7 54c-2.8 3.3-3.2 7.9-1 11.7l82.1 142c11.2-6.1 23.4-10.2 36-12.1L74.8 78c-3.2-5.6-1.3-12.7 4.3-15.9 1.8-1 3.8-1.6 5.8-1.6h146.7c6.4 0 11.6 5.2 11.6 11.7 0 2-.5 4-1.6 5.8l-67.9 117.7c12.6 1.8 24.8 5.9 36 12.1l82-142c2.1-3.7 1.7-8.4-1-11.6l-23-27.2c-1.9-2.3-4.8-3.6-7.7-3.6l-203.6-.1zm48.7 60.5l53.2 92.1 53.2-92.1H105.1zm45.1 192.9l-4.4 2.7c-1.1.8-2.4 1.2-3.8 1.2-4.4 0-7.4-4.7-7.4-9.4-.1-3.3 1.6-6.3 4.4-8l20-12.1c1.3-.8 2.9-1.1 4.4-1.1 4.8 0 10.4 2.9 10.4 7.4v96.2c0 4.9-5.9 7.3-11.8 7.3s-11.8-2.4-11.8-7.3v-76.9z"
        />
      </svg>
    ),
    title: "Activity Tracking.",
    title_ar: "تتبع النشاط",
    description:
      "track every actions happens in your gym from one place and stay ahead of other gyms.",
    description_ar:
      "تتبع كل حركة في صالة الألعاب الرياضية الخاصة بك من مكان واحد ، وابقَ في صدارة صالات الألعاب الرياضية الأخرى.",
  },
  {
    id: 2,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <circle class="lineal-fill" cx="283.8" cy="283.8" r="143" />
        <path
          class="lineal-stroke"
          d="M283.8 441.8c-87.1 0-158-70.9-158-158s70.9-158 158-158 158 70.9 158 158-70.9 158-158 158zm0-286.1c-70.7 0-128.1 57.3-128.1 128.1S213 411.9 283.8 411.9s128.1-57.3 128.1-128.1c-.1-70.7-57.4-128-128.1-128.1z"
        />
        <path
          class="lineal-fill"
          d="M283.8 210.5c40.5 0 73.3 32.8 73.3 73.3s-32.8 73.3-73.3 73.3-73.3-32.8-73.3-73.3"
        />
        <path
          class="lineal-stroke"
          d="M283.8 372.1c-48.7-.1-88.2-39.5-88.2-88.3.2-8.2 7.1-14.8 15.3-14.5 7.9.2 14.3 6.6 14.5 14.5 0 32.2 26.1 58.4 58.4 58.4s58.4-26.1 58.4-58.4-26.1-58.4-58.4-58.4c-8.2-.2-14.8-7.1-14.5-15.3.2-7.9 6.6-14.3 14.5-14.5 48.7-.5 88.7 38.6 89.2 87.3s-38.6 88.7-87.3 89.2h-1.9z"
        />
        <path
          class="lineal-fill"
          d="M107.9 55.1l-5.3 47.5-47.5 5.3-40.2-40.1 52.9-52.9z"
        />
        <path
          class="lineal-stroke"
          d="M283.8 55.7c-55.3 0-108.8 20.1-150.4 56.7l-15.2-15.2 4.5-40.4c.5-4.5-1.1-9-4.3-12.2L78.3 4.4c-5.8-5.8-15.3-5.8-21.1 0L4.4 57.2c-5.8 5.8-5.8 15.3 0 21.1l40.1 40.1c2.8 2.8 6.6 4.4 10.6 4.4.5 0 1.1 0 1.6-.1l40.4-4.5 15.2 15.2c-36.6 41.5-56.7 95-56.7 150.4C55.7 409.6 158 512 283.8 512c66.2 0 129.1-28.7 172.4-78.7 5.6-6.1 5.1-15.6-1-21.1s-15.6-5.1-21.1 1l-.5.5c-37.7 43.4-92.3 68.4-149.8 68.4-109.3 0-198.3-89-198.3-198.3 0-47.4 17-93.3 48-129.2l140.2 140.2c5.8 5.8 15.3 5.8 21.1 0s5.8-15.3 0-21.1L154.6 133.5c35.9-31 81.8-48 129.2-48 109.4 0 198.3 89 198.3 198.3 0 8.3 6.7 14.9 14.9 14.9s14.9-6.7 14.9-14.9C512 158 409.6 55.7 283.8 55.7zM36.1 67.8l31.7-31.7 24.5 24.5-3.2 28.5-28.5 3.2-24.5-24.5z"
        />
      </svg>
    ),
    title: "Sales & Target",
    title_ar: "المبيعات",
    description:
      "Grow your revenues from accepting online payment, and let sales agents work smarter using the gym team app.",
    description_ar:
      "يمكنك زيادة إيراداتك عن طريق قبول الدفع عبر الإنترنت ، والسماح لموظفي المبيعات بالعمل بشكل أكثر ذكاءً باستخدام تطبيق Gymme Team.",
  },
  {
    id: 3,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 409.6 391.2"
      >
        <path
          class="lineal-fill"
          d="M148.9 61.3C136 24.4 95.7 4.9 58.8 17.8S2.4 71.1 15.3 108c6.5 18.5 20.3 33.5 38.3 41.4 15.9-42.8 51.3-75.5 95.3-88.1z"
        />
        <path
          class="lineal-stroke"
          d="M53.5 160.7c-1.6 0-3.1-.4-4.6-1C7.5 141.4-11.3 93 7 51.5s66.8-60.3 108.2-42c20.8 9.2 36.9 26.6 44.4 48.1 2.1 5.9-1 12.3-6.9 14.4-.2.1-.4.1-.6.2-40.5 11.6-73.1 41.7-87.9 81.1-1.8 4.5-6 7.4-10.7 7.4zM82.1 25.2c-32.8 0-59.5 26.6-59.5 59.5 0 19.4 9.5 37.7 25.4 48.8C65.2 97.2 95.8 69 133.4 54.7c-10.7-18.3-30.2-29.5-51.3-29.5z"
        />
        <path
          class="lineal-fill"
          d="M327.5 13.9c-30.1 0-56.9 19-66.8 47.5 44 12.6 79.4 45.2 95.4 88.1 35.8-15.8 52-57.6 36.2-93.3-11.3-25.8-36.7-42.3-64.8-42.3z"
        />
        <path
          class="lineal-stroke"
          d="M356.1 160.7c-4.7 0-8.9-2.9-10.6-7.4-14.8-39.5-47.4-69.6-87.9-81.2-6-1.7-9.5-8-7.8-14 .1-.2.1-.4.2-.6C264.9 14.7 311.7-7.9 354.5 7S420 68.8 405 111.7c-7.5 21.5-23.6 38.9-44.4 48.1-1.4.6-2.9.9-4.5.9zM276.2 54.6c37.6 14.3 68.1 42.5 85.4 78.8 26.9-18.8 33.5-55.9 14.7-82.8s-55.9-33.5-82.8-14.7c-7.1 4.9-13 11.3-17.3 18.7zm-79.4 176c-3.1 0-6.1-.7-8.8-2.1l-45.1-22.6c-5.6-2.8-7.8-9.6-5-15.2s9.6-7.8 15.2-5l43.3 21.6 57.4-57.4c4.4-4.4 11.6-4.4 16 0s4.4 11.6 0 16l-58.9 58.9c-3.8 3.7-8.8 5.8-14.1 5.8z"
        />
        <path
          class="lineal-stroke"
          d="M204.8 391.2c-96.8 0-175.6-78.8-175.6-175.6S108 39.9 204.8 39.9c69.8 0 133 41.3 161 105.2 2.5 5.7-.1 12.4-5.9 14.9s-12.4-.1-14.9-5.9c-24.4-55.7-79.4-91.7-140.2-91.7-84.4 0-153 68.6-153 153s68.6 153 153 153 153-68.6 153-153c-.2-6.2 4.7-11.5 11-11.6 6.2-.2 11.5 4.7 11.6 11v.7c0 96.9-78.8 175.7-175.6 175.7z"
        />
        <path
          class="lineal-stroke"
          d="M287.1 281.5c-1.9 0-3.7-.5-5.4-1.4l-102.4-55.7c-5.5-3-7.5-9.8-4.5-15.3s9.8-7.5 15.3-4.5l102.5 55.7c5.5 3 7.5 9.8 4.5 15.3-2 3.6-5.8 5.9-10 5.9zm-82.3-178.4c-6.2 0-11.3-5-11.3-11.3V51.2c0-6.2 5.1-11.3 11.3-11.3s11.3 5.1 11.3 11.3v40.5c0 6.3-5 11.3-11.3 11.4zm0 288.1c-6.2 0-11.3-5.1-11.3-11.3v-40.5c0-6.2 5.1-11.3 11.3-11.3s11.3 5.1 11.3 11.3v40.5c0 6.2-5 11.3-11.3 11.3zm164.3-164.3h-40.5c-6.2.2-11.5-4.7-11.6-11-.2-6.2 4.7-11.5 11-11.6h41.2c6.2.2 11.2 5.4 11 11.6-.3 6-5.1 10.8-11.1 11zm-288.1 0H40.5c-6.2-.2-11.2-5.4-11-11.6.2-6 5-10.8 11-11H81c6.2.2 11.2 5.4 11 11.6-.2 6-5 10.8-11 11z"
        />
        <path
          class="lineal-stroke"
          d="M241.2 62.5c-6.2 0-11.3-5.1-11.3-11.3V27.9c0-2.9-2.4-5.3-5.3-5.3H185c-2.9 0-5.3 2.4-5.3 5.3v23.4c0 6.2-5.1 11.3-11.3 11.3s-11.3-5.1-11.3-11.3V27.9C157.1 12.5 169.6 0 185 0h39.6c15.4 0 27.9 12.5 27.9 27.9v23.4c-.1 6.2-5.1 11.2-11.3 11.2z"
        />
      </svg>
    ),
    title: "Attendance Reports",
    title_ar: "تقارير الحضور",
    description:
      "know when Members and Employees are visiting and leaving from the gym by day and time.",
    description_ar:
      "تعرف على وقت زيارة الأعضاء والموظفين للصالة الرياضية ومغادرتهم لها باليوم والساعة.",
  },
  {
    id: 4,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 409.6 284.94"
      >
        <path
          class="lineal-fill"
          d="M314.73 101.82a84 84 0 11-84.05-84 84 84 0 0184.05 84.05z"
        />
        <path
          class="lineal-stroke"
          d="M230.69 196.51a94.69 94.69 0 1194.69-94.69 94.8 94.8 0 01-94.69 94.69zm0-168.1a73.41 73.41 0 1073.41 73.41 73.49 73.49 0 00-73.41-73.41z"
        />
        <path
          class="lineal-stroke"
          d="M330.4 284.94H30.19A30.21 30.21 0 010 254.76V111.5a30.22 30.22 0 0130.19-30.19h29.46a10.64 10.64 0 110 21.28H30.19a8.91 8.91 0 00-8.91 8.91v143.26a8.92 8.92 0 008.91 8.91H330.4a8.92 8.92 0 008.91-8.91v-18.13a10.64 10.64 0 1121.28 0v18.13a30.21 30.21 0 01-30.19 30.18z"
        />
        <path
          class="lineal-stroke"
          d="M379.41 203.64H79.19A30.22 30.22 0 0149 173.45V30.19A30.22 30.22 0 0179.19 0h300.22a30.22 30.22 0 0130.19 30.19v22.38a10.64 10.64 0 01-21.28 0V30.19a8.92 8.92 0 00-8.91-8.91H79.19a8.92 8.92 0 00-8.9 8.91v143.26a8.92 8.92 0 008.9 8.91h300.22a8.91 8.91 0 008.91-8.91V110a10.64 10.64 0 0121.28 0v63.42a30.22 30.22 0 01-30.19 30.19z"
        />
        <path class="lineal-fill" d="M99.8 193a84 84 0 00167.45 0z" />
        <path
          class="lineal-stroke"
          d="M183.52 280.55a94.66 94.66 0 01-94.33-86.66 10.66 10.66 0 0110.61-11.53h167.45a10.64 10.64 0 0110.6 11.53 94.66 94.66 0 01-94.33 86.66zm-71.2-76.91c8 32 37.32 55.64 71.2 55.64s63.18-23.66 71.21-55.64zm114.02-61.39c-13.9-.34-24.34-7.81-24.34-14.25 0-3.22 2.87-8 6.44-8 4.36 0 7.81 7.47 17.92 8.62v-22.91c-10.45-3.91-22.29-8.39-22.29-23.09s10.8-20.92 22.29-22.41V57c0-1.49 1.73-2.87 3.91-2.87 1.95 0 3.91 1.38 3.91 2.87v3c7.46.23 19.64 2.41 19.64 9.08 0 2.64-1.84 7.92-6.09 7.92-3.21 0-5.86-3.67-13.55-4.25V93c10.22 3.79 21.71 9 21.71 24.58 0 14.14-8.85 22.3-21.71 24.36v3.56c0 1.5-2 2.88-3.91 2.88-2.18 0-3.91-1.38-3.91-2.88zm.92-51.82V73.08c-5.74.92-9.19 3.68-9.19 8 0 5.06 3.91 7.24 9.19 9.31zm6 18v20c5-1 8.61-4 8.61-9.42 0-5.63-3.67-8.39-8.61-10.57z"
        />
      </svg>
    ),
    title: "Cashflow Report",
    title_ar: "تقرير الايرادات",
    description:
      "Automatically calculate your earning and expenses from subscriptions, bar, bills and salaries.",
    description_ar:
      "احسب أرباحك ومصاريفك تلقائيًا من الاشتراكات و بيع المنتجات والمصروفات والرواتب.",
  },
  {
    id: 5,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 466.4"
      >
        <path
          class="lineal-fill"
          d="M201.4 172.5h-96l26.1-158.4h83zm205.2 0h-96L297.5 14.1h83zm-216.7 97.1c7.2-7 16.8-10.9 26.9-10.8 9.6 0 18.8 3.1 26.9 5.8 4 1.4 8.1 2.6 12.3 3.5 4.2-.9 8.3-2.1 12.3-3.5 8.1-2.7 17.3-5.8 26.9-5.8 10-.1 19.7 3.7 26.9 10.8 24.4 22.9 15.9 48.4 10.9 63.7l-.3.8c-4.6 14-22 59.8-51.4 59.8-15.6 0-20.9-2.5-24.8-4.4l-.6-.3-.6.3c-3.9 1.9-9.1 4.4-24.8 4.4-29.3 0-46.7-45.8-51.4-59.8l-.3-.8c-4.8-15.3-13.3-40.8 11.1-63.7z"
        />
        <path
          class="lineal-stroke"
          d="M511.1 147.4l-6.4-25.9c-1.9-7.6-9.6-12.1-17.2-10.2-7.5 1.9-12.1 9.5-10.3 17l6.4 25.9c.4 1.8-.7 3.6-2.5 4.1-.3.1-.5.1-.8.1h-61.7l-14.2-86-7.3-44.1h52.6c1.5 0 2.9 1 3.2 2.5l6.6 26.8c1.9 7.6 9.6 12.1 17.2 10.2 7.5-1.9 12.1-9.5 10.3-17L480.5 24C477 9.9 464.3 0 449.8 0H62.2C47.7 0 35 9.9 31.5 24L.9 147.4c-4.2 17 6.1 34.1 23.1 38.3 2.5.6 5.1.9 7.6.9h8.1v265.6c0 7.8 6.3 14.1 14.2 14.1h404.2c7.8 0 14.1-6.3 14.1-14.1V257.8c0-7.8-6.3-14.2-14.1-14.2s-14.2 6.3-14.2 14.1V438H68V186.7h412.4c17.5 0 31.6-14.2 31.6-31.6 0-2.6-.3-5.2-.9-7.7zM227.5 28.3h56.9l10.8 130.1h-78.4l10.7-130.1zM29 157.1c-.7-.8-.9-1.9-.6-2.9L59 30.8c.4-1.5 1.7-2.5 3.2-2.5h52.6l-5.3 32.1-16.2 98H31.6c-1 0-2-.5-2.6-1.3zm98.3-30.8l16.2-98h55.6l-10.7 130.1H122l5.3-32.1zm196.3 32.1L315.2 57l-2.4-28.7h55.6l16.2 98 5.3 32.1h-66.3z"
        />
        <path
          class="lineal-stroke"
          d="M256 220c-7.8 0-14.1 6.3-14.1 14.1v15c-7.4-2.3-15.9-4.4-25-4.4-13.6-.1-26.8 5.1-36.6 14.6-31 29.1-19.9 62.4-14.6 78.4l.3.8c6.9 20.9 27 69.5 64.8 69.5 12.6 0 19.9-1.5 25.3-3.4 5.4 2 12.8 3.4 25.3 3.4 37.8 0 57.9-48.6 64.8-69.5l.3-.8c5.3-16 16.3-49.3-14.6-78.4-9.8-9.5-22.9-14.7-36.6-14.6-9.1 0-17.6 2.1-25 4.4v-15c-.1-7.8-6.4-14.1-14.3-14.1zm39.2 53c6.4-.1 12.7 2.4 17.2 6.9 16.5 15.5 13 31.3 7.1 48.9l-.3.8c-8.2 24.9-23.2 50.1-37.9 50.1-12.4 0-15.8-1.7-18.6-3l-.7-.3c-3.9-1.8-8.3-1.8-12.2 0l-.6.3c-2.8 1.3-6.2 3-18.6 3-14.8 0-29.7-25.1-37.9-50l-.3-.8c-5.8-17.6-9.3-33.4 7.1-48.9 4.5-4.6 10.8-7.1 17.2-6.9 7.3 0 15 2.6 22.5 5 4.6 1.7 9.4 3 14.2 4 1.7.3 3.3.3 5 0 4.8-1 9.6-2.3 14.2-4 7.6-2.6 15.3-5.1 22.6-5.1z"
        />
      </svg>
    ),
    title: "Bar Management",
    title_ar: "إدارة البار",
    description: "Manage your stock, orders and leftover orders from members.",
    description_ar: "قم بإدارة المخزن و الطلبات و الطلبات بالأجل من الأعضاء.",
  },
  {
    id: 6,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 409.6 356.2"
      >
        <path
          class="lineal-fill"
          d="M265.2 180.7H144.4v-17.6c0-33.3 27-60.4 60.4-60.4s60.4 27 60.4 60.4v17.6z"
        />
        <path
          class="lineal-stroke"
          d="M265.2 191.7H144.4c-6.1 0-11.1-5-11.1-11.1V163c-.5-39.5 31.1-71.8 70.6-72.3s71.8 31.1 72.3 70.6v19.3c0 6.2-4.9 11.1-11 11.1zm-109.7-22.1h98.6v-6.5c.4-27.2-21.4-49.6-48.6-50s-49.6 21.4-50 48.6v7.9z"
        />
        <path
          class="lineal-fill"
          d="M250.4 56.7c0 25.2-20.4 45.6-45.6 45.6s-45.6-20.4-45.6-45.6 20.4-45.6 45.6-45.6c25.2 0 45.6 20.4 45.6 45.6z"
        />
        <path
          class="lineal-stroke"
          d="M204.8 113.3c-31.3 0-56.7-25.4-56.7-56.7S173.5-.1 204.8-.1s56.7 25.4 56.7 56.7c-.1 31.3-25.4 56.7-56.7 56.7zm0-91.2c-19.1 0-34.5 15.4-34.5 34.5s15.4 34.5 34.5 34.5 34.5-15.4 34.5-34.5c0-19-15.5-34.4-34.5-34.5zm193.7 334.1H296.8c-6.1 0-11.1-5-11.1-11.1v-14.8c0-34.2 27.8-62 62-62 6.1-.2 11.2 4.6 11.4 10.7s-4.6 11.2-10.7 11.4h-.7c-22 0-39.8 17.8-39.8 39.8v3.8h79.6v-3.7c0-3.2-.4-6.5-1.2-9.6-1.4-6 2.3-11.9 8.3-13.3 5.8-1.4 11.7 2.2 13.2 8 1.2 4.9 1.8 9.9 1.8 14.9v14.8c0 6.1-4.9 11.1-11.1 11.1z"
        />
        <path
          class="lineal-stroke"
          d="M347.6 290.5c-27.3 0-49.5-22.2-49.5-49.5s22.2-49.5 49.5-49.5 49.5 22.2 49.5 49.5-22.2 49.5-49.5 49.5zm0-76.9c-15.1 0-27.3 12.3-27.3 27.4 0 15.1 12.3 27.3 27.4 27.3S375 256 375 241c0-15.1-12.3-27.3-27.4-27.4zM112.8 356.2H11.1c-6.1 0-11.1-5-11.1-11.1v-14.8c0-34.2 27.8-62 62-62 6.1.2 10.9 5.3 10.7 11.4-.2 5.9-4.9 10.6-10.7 10.7-22 0-39.8 17.8-39.8 39.8v3.8h79.6v-3.7c0-3.2-.4-6.5-1.2-9.6-1.5-5.9 2.2-11.9 8.1-13.4s11.9 2.2 13.4 8.1c1.2 4.9 1.8 9.9 1.8 14.9v14.8c.1 6.1-4.9 11.1-11.1 11.1.1 0 .1 0 0 0z"
        />
        <path
          class="lineal-stroke"
          d="M61.9 290.5c-27.3 0-49.5-22.2-49.5-49.5s22.2-49.5 49.5-49.5 49.5 22.2 49.5 49.5-22.1 49.5-49.5 49.5zm0-76.9c-15.1 0-27.3 12.2-27.3 27.4 0 15.1 12.2 27.4 27.4 27.4 15.1 0 27.3-12.2 27.3-27.3 0-15.2-12.3-27.4-27.4-27.5zm142.9 72.1c-6.1 0-11.1-5-11.1-11.1v-56.4c-.2-6.1 4.6-11.2 10.7-11.4 6.1-.2 11.2 4.6 11.4 10.7v57.1c.1 6.2-4.9 11.1-11 11.1z"
        />
        <path
          class="lineal-stroke"
          d="M279.5 285.7H130.1c-6.1.2-11.2-4.6-11.4-10.7s4.6-11.2 10.7-11.4h150.2c6.1-.2 11.2 4.6 11.4 10.7s-4.6 11.2-10.7 11.4h-.8z"
        />
      </svg>
    ),
    title: "Activity Sharing",
    title_ar: "مشاركة النشاط",
    description:
      "Connect every area of your gym in one dashboard and manage account access.",
    description_ar:
      "قم بتوصيل كل أقسام الصالة الرياضية الخاصة بك في لوحة تحكم واحدة وإدارة صلاحيات كل حساب.",
  },
];

export default Features;
