import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/red.css";
import img from "../assets/img/imags/tm1.jpg";
import img2x from "../assets/img/imags/tm1@2x.jpg";
import { useContext } from "react";
import Navbar from "../Navbar/Navbar";
import ContactForm from "../Form/ContactForm";
import Config from "../../Config";
import AppContext from "../../AppContext";

function Contact() {
  const { translate, systemLang } = useContext(AppContext);
  const t = (k) => translate(k);

  return (
    <>
      <Navbar />
      <div className="wrapper bg-soft-primary text-white">
        <div className="container pt-18 pt-md-20 pb-21 pb-md-21 text-center">
          <div className="row">
            <div className="col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-5 mx-auto">
              <h1 className="display-1 text-white mb-3 text-primary">
                {t("contact_title")}
              </h1>
              <p className="lead px-xl-10 px-xxl-10 text-dark">
                {t("contact_description")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`wrapper bg-light ${systemLang}`}>
        <div className="container pb-14 pb-md-1">
          <div className="row">
            <div className="col mt-n19 mb-1">
              <div className="card shadow-lg">
                <div className="row gx-0">
                  <div
                    className="col-lg-6 image-wrapper bg-image bg-cover rounded-top rounded-lg-start d-none d-md-block"
                    // data-image-src={img}
                    style={{ backgroundImage: `url(${img2x})` }}
                  ></div>

                  <div className="col-lg-6">
                    <div className="p-10 p-md-11 p-lg-13">
                      <h2 className="display-4 mb-3 text-primary">
                        {t("contact_Talk")}
                      </h2>
                      <p className="lead fs-lg">
                        {t("contact_Hello")}
                        <br />
                        {t("contact_friend")}
                      </p>
                      <p>{t("contact_coffee")}</p>
                      <a
                        href="https://www.facebook.com/groups/gymmecommunity"
                        className="btn btn-primary rounded-pill mt-2"
                      >
                        {t("contact_button")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrapper bg-light">
            <div className="container py-14 py-md-16">
              <div className="card bg-soft-primary">
                <div className="card-body p-5 p-lg-12">
                  <div className="row gx-md-8 gx-xl-12 gy-10">
                    <div className="col-lg-4">
                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 mt-n1">
                            {" "}
                            <i className="uil uil-location-pin-alt"></i>{" "}
                          </div>
                        </div>
                        <div>
                          <h5 className="mb-1">{t("contact_Address")}</h5>
                          <address>{t("contact_Address_description")}</address>
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 mt-n1">
                            {" "}
                            <i className="uil uil-phone-volume"></i>{" "}
                          </div>
                        </div>
                        <div>
                          <h5 className="mb-1">{t("contact_Phone")}</h5>
                          <p>
                            {Config?.phones?.map?.((phone, index) => (
                              <p key={index} className="mb-0">
                                <a href={`tel:${phone}`} className="link-body">
                                  {phone}
                                </a>
                              </p>
                            ))}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div>
                          <div className="icon text-primary fs-28 mt-n1">
                            {" "}
                            <i className="uil uil-envelope"></i>{" "}
                          </div>
                        </div>
                        <div>
                          <h5 className="mb-1">{t("contact_E_mail")}</h5>
                          {Config?.emails?.map?.((email, index) => (
                            <p key={index} className="mb-0">
                              <a href={`mailto:${email}`} className="link-body">
                                {" "}
                                {email}{" "}
                              </a>
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
