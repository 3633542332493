const MobileFeatures = [
  {
    id: 1,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 382.7 512"
      >
        <circle class="lineal-fill" cx="191.4" cy="192" r="106.2" />
        <path
          class="lineal-stroke"
          d="M191.4 312.6c-66.6 0-120.6-54-120.6-120.6s54-120.6 120.6-120.6S312 125.4 312 192c-.1 66.6-54.1 120.5-120.6 120.6zm0-212.5c-50.7 0-91.9 41.1-91.9 91.9s41.1 91.9 91.9 91.9c50.7 0 91.9-41.1 91.9-91.9-.1-50.7-41.2-91.8-91.9-91.9z"
        />
        <path
          class="lineal-stroke"
          d="M191.4 512c-3.6 0-7.1-1.3-9.7-3.8-1.8-1.7-45.7-42.2-90.2-101.2C30.8 326.7 0 254.1 0 191.4c-.1-29.6 6.8-58.9 20-85.4 3.4-7.2 11.9-10.3 19.1-6.9S49.4 111 46 118.2l-.3.6c-11.3 22.5-17.1 47.4-17 72.5 0 116.8 126.3 250.3 162.7 286.4 11-10.9 30.3-30.6 51.6-56 5.1-6.1 14.2-6.9 20.2-1.8s6.9 14.2 1.8 20.2c-34.3 40.8-62.7 66.9-63.9 68-2.7 2.5-6.2 3.9-9.7 3.9zm110.1-129.4c-7.9 0-14.4-6.4-14.3-14.4 0-2.9.9-5.6 2.4-8C332.3 296.9 354 240 354 191.3c0-89.7-73-162.6-162.6-162.6-34.6-.1-68.3 10.9-96.2 31.5-6.4 4.7-15.4 3.3-20.1-3.1S71.8 41.7 78.2 37C111 12.9 150.6-.1 191.4 0c105.5 0 191.4 85.8 191.4 191.4 0 54.5-23.3 116.8-69.2 184.9-2.8 3.9-7.3 6.3-12.1 6.3z"
        />
        <path
          class="lineal-stroke"
          d="M183.6 235.1c-3.8 0-7.5-1.5-10.1-4.2L134.6 192c-5.6-5.6-5.6-14.7 0-20.3s14.7-5.6 20.3 0l28.8 28.8 44.4-44.4c5.7-5.5 14.8-5.4 20.3.4 5.4 5.6 5.4 14.4 0 19.9l-54.5 54.5c-2.9 2.6-6.5 4.2-10.3 4.2z"
        />
      </svg>
    ),
    title: "Explore nearby Gyms",
    title_ar: "أكتشف الجيمات القريبة منك",
    description:
      "explore all the nearby gyms around you and know everything about them like timing, attendance types, subscriptions, and much more. .",
    description_ar:
      "استكشف جميع الصالات الرياضية القريبة من حولك واعرف كل شيء عنها مثل التوقيت وأنواع الحضور والاشتراكات وغير ذلك الكثير.",
  },
  {
    id: 2,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 506.19 512"
      >
        <path
          class="lineal-stroke"
          d="M146.33 288.23v-46.51c18.56 3.25 21.36 13.54 21.78 19.59a2.93 2.93 0 002.94 2.75h20.27a3 3 0 003-3.14c-1.32-25.51-18.8-41.92-47.95-45.61v-15.64a3 3 0 00-3-2.95h-20.26a3 3 0 00-3 2.95v15.64c-30.32 3.84-48 21.45-48 48.75s17.7 44.91 48 48.75v46.51c-18.56-3.26-21.35-13.55-21.77-19.6a3 3 0 00-2.9-2.72H75.16a3 3 0 00-3 3.14C73.52 365.62 91 382 120.15 385.72v15.64a3 3 0 002.95 2.95h20.28a3 3 0 003-2.95v-15.64c30.33-3.83 48-21.45 48-48.75s-17.72-44.91-48.05-48.74zm-26.18-1.82C98.31 282.58 98.3 269 98.3 264.07s0-18.52 21.85-22.35zm26.18 72.91v-44.69c21.85 3.83 21.85 17.4 21.85 22.34s0 18.52-21.85 22.35z"
        />
        <path
          class="lineal-stroke"
          d="M407.05 512H15.86A15.86 15.86 0 010 496.14V160.78a15.86 15.86 0 0131.72 0v319.5h375.33a15.86 15.86 0 110 31.72z"
        />
        <path
          class="lineal-fill"
          d="M388.37 138.963l50.005-85.2 51.944 30.488-50.006 85.2z"
        />
        <path
          class="lineal-fill"
          d="M137.92 126.64h257.71l42.75-72.85 35 20.54a69.55 69.55 0 00-68.43-58.45H82.51a69.49 69.49 0 0169.29 69.29 68.86 68.86 0 01-13.88 41.47z"
        />
        <path
          class="lineal-stroke"
          d="M505.68 80.29a15.86 15.86 0 00-7.32-9.68l-10.93-6.42C477.91 27.12 443.75 0 404.94 0H86.11A86 86 0 000 86a15.86 15.86 0 0031.72 0A54.54 54.54 0 0181.6 31.7h.91a53.49 53.49 0 0153.43 53.43 52.81 52.81 0 01-10.7 32 15.85 15.85 0 0012.68 25.38h230L246.17 349.94a15.87 15.87 0 00-1.57 12.38l11.42 40a15.86 15.86 0 0015.24 11.51 16.29 16.29 0 003.65-.42l40.52-9.55a15.83 15.83 0 0010-7.41l65.72-112v135.62a15.86 15.86 0 0031.72 0V228.49a16.21 16.21 0 00-30-9.62l-91.32 155.71-19.18 4.51-5.37-18.94L394 160.7l38.26 22.46a15.87 15.87 0 0021.74-5.65l50-85.2a15.85 15.85 0 001.68-12.02zM148.76 31.74h256.18A52.94 52.94 0 01432 39.26l-.17.09a17.64 17.64 0 00-1.92 1l-.28.2a14.82 14.82 0 00-1.7 1.28l-.13.11a15.63 15.63 0 00-1.55 1.6l-.29.36a16.45 16.45 0 00-1.29 1.85l-38.16 65H163.72a85.15 85.15 0 00-15-79.05zm285.91 116l-24.58-14.43L444 75.5l24.58 14.43z"
        />
      </svg>
    ),
    title: "Subscribe or renewal",
    title_ar: "أشتراك او جدد أشتراكك",
    description:
      "Subscribe to the latest subscriptions & classes in the gyms around you and renew your current subscriptions.",
    description_ar:
      "اشترك في أحدث الاشتراكات والفصول في الصالات الرياضية من حولك وجدد اشتراكاتك الحالية.",
  },
  {
    id: 3,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 302.1 409.6"
      >
        <path
          class="lineal-stroke"
          d="M255 409.6H47c-25.9 0-47-21.1-47-47V118c.1-25.9 21.1-46.9 47-47h208c25.9.1 46.9 21.1 47 47v244.6c0 25.9-21.1 46.9-47 47zM47 92.8c-13.9 0-25.2 11.3-25.2 25.2v244.6c0 13.9 11.3 25.2 25.2 25.2h208c13.9 0 25.2-11.3 25.2-25.2V118c0-13.9-11.3-25.2-25.2-25.2H47z"
        />
        <path
          class="lineal-fill"
          d="M206 49.4h-15.7c-.5-21.7-18.5-38.9-40.2-38.4-21 .5-37.9 17.4-38.4 38.4H96.1c-18 .3-32.3 15.1-32 33.1.3 17.5 14.4 31.6 32 32H206c17.9-.8 31.9-15.9 31.1-33.9-.7-17-14.2-30.5-31.1-31.2z"
        />
        <path
          class="lineal-stroke"
          d="M206 125.3H96.1c-24 0-43.4-19.4-43.4-43.4s19.4-43.4 43.4-43.4h6.1c6.5-26.9 33.5-43.5 60.5-37.1 18.4 4.4 32.7 18.7 37.1 37.1h6.2c24 0 43.4 19.4 43.4 43.4S230 125.3 206 125.3zm-109.8-65c-11.9 0-21.6 9.7-21.6 21.6 0 11.9 9.7 21.6 21.6 21.6H206c11.9 0 21.6-9.7 21.6-21.6 0-11.9-9.7-21.6-21.6-21.6h-15.7c-5.9 0-10.8-4.7-10.9-10.7-.3-15.7-13.2-28.1-28.9-27.8-15.3.3-27.5 12.6-27.8 27.8-.1 5.9-5 10.7-10.9 10.7H96.2zm124.2 130.2H81.7c-6-.2-10.8-5.2-10.6-11.2.2-5.8 4.8-10.4 10.6-10.6h138.7c6-.2 11.1 4.6 11.2 10.6.2 6-4.6 11.1-10.6 11.2h-.6zm0 69.4H81.7c-6-.2-10.8-5.2-10.6-11.2.2-5.8 4.8-10.4 10.6-10.6h138.7c6-.2 11.1 4.6 11.2 10.6.2 6-4.6 11.1-10.6 11.2h-.6zm0 69.3H81.7c-6-.2-10.8-5.2-10.6-11.2.2-5.8 4.8-10.4 10.6-10.6h138.7c6-.2 11.1 4.6 11.2 10.6.2 6-4.6 11.1-10.6 11.2h-.6z"
        />
      </svg>
    ),
    title: "See your gym subscription details",
    title_ar: "أعرف تفاصيل أشتراك جيمك",
    description:
      "Know your gym subscription details like your ID number, subscription expiration date, your VIP sessions, bar orders, schedule your next training session, and much more.",
    description_ar:
      "تعرف على تفاصيل اشتراكك في الجيم مثل رقم الهوية وتاريخ انتهاء الاشتراك والحصص الخاصة مثل (السونا)  وطلبات البار وجدولة جلسة التدريب التالية وغير ذلك الكثير.",
  },
  {
    id: 4,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 418.2 512"
      >
        <path class="lineal-fill" d="M14.8 97.6h85.4V14.8z" />
        <path
          class="lineal-stroke"
          d="M100.2 112.4H14.8C6.6 112.4 0 105.8 0 97.7c0-4 1.6-7.9 4.5-10.7L89.9 4.2c5.8-5.7 15.2-5.6 20.9.3 2.7 2.8 4.2 6.5 4.2 10.3v82.8c0 8.2-6.6 14.8-14.8 14.8zm-49-29.6h34.2V49.7L51.2 82.8zM403.4 320c-8.2 0-14.8-6.6-14.8-14.8v-269c0-3.6-3.2-6.6-7.2-6.6H168.7c-8.2 0-14.8-6.6-14.8-14.8 0-8.2 6.6-14.8 14.8-14.8h212.6c20.3 0 36.8 16.2 36.8 36.1v269c.1 8.3-6.5 14.9-14.7 14.9z"
        />
        <path
          class="lineal-stroke"
          d="M381.4 512H36.8C16.5 512 0 495.8 0 475.9V97.6c0-4 1.6-7.8 4.5-10.6L89.9 4.2c5.9-5.7 15.2-5.5 20.9.3 5.7 5.9 5.5 15.2-.3 20.9l-80.9 78.5v372c0 3.6 3.2 6.6 7.2 6.6h344.6c4 0 7.2-3 7.2-6.6v-92.7c.2-8.2 7-14.6 15.2-14.4 7.9.2 14.2 6.5 14.4 14.4v92.7c0 19.9-16.5 36.1-36.8 36.1z"
        />
        <path
          class="lineal-stroke"
          d="M403.4 112.4H168.7c-8.2-.2-14.6-7-14.4-15.2.2-7.9 6.5-14.2 14.4-14.4h219.9v-43c0-5.7-4.6-10.3-10.3-10.3H168.7c-8.2 0-14.8-6.6-14.8-14.8C154 6.6 160.6 0 168.7 0h209.6c22 0 39.8 17.9 39.9 39.8v57.8c0 8.2-6.6 14.8-14.8 14.8z"
        />
        <path class="lineal-fill" d="M73 189.6h124.1v65H73z" />
        <path
          class="lineal-stroke"
          d="M197.1 269.4H73c-8.2 0-14.8-6.6-14.8-14.8v-65c0-8.2 6.6-14.8 14.7-14.8H197c8.2 0 14.8 6.6 14.8 14.8v65c.1 8.1-6.5 14.8-14.7 14.8.1 0 0 0 0 0zM87.8 239.8h94.6v-35.5H87.8v35.5zm201 25.3c-3.9 0-7.7-1.6-10.5-4.3l-23.6-23.6c-5.7-5.9-5.6-15.2.3-20.9 5.7-5.6 14.9-5.6 20.6 0l13.2 13.2 46-46c5.9-5.7 15.2-5.6 20.9.3 5.6 5.7 5.6 14.9 0 20.6l-56.4 56.4c-2.8 2.7-6.6 4.3-10.5 4.3z"
        />
        <path class="lineal-fill" d="M73 346.1h124.1v65H73z" />
        <path
          class="lineal-stroke"
          d="M197.1 426H73c-8.2 0-14.8-6.6-14.8-14.8v-65c0-8.2 6.5-14.8 14.7-14.9h124.2c8.2 0 14.8 6.6 14.8 14.8v65c0 8.2-6.5 14.9-14.8 14.9.1 0 .1 0 0 0zM87.8 396.4h94.6v-35.5H87.8v35.5zm201 25.3c-3.9 0-7.7-1.6-10.5-4.3l-23.6-23.6c-5.7-5.9-5.6-15.2.3-20.9 5.7-5.6 14.9-5.6 20.6 0l13.2 13.2 46-46c5.9-5.7 15.2-5.6 20.9.3 5.6 5.7 5.6 14.9 0 20.6l-56.4 56.4c-2.8 2.7-6.6 4.3-10.5 4.3z"
        />
      </svg>
    ),
    title: "Schedule your training",
    title_ar: "أضف و رتب تمارينك",
    description:
      "Add all your training for each muscle and then schedule each training to which day you will train, to get notified with your scheduled training for this day.",
    description_ar:
      "أضف كل تمارينك لكل عضلة ثم حدد موعدًا لكل تدريب في اليوم الذي ستتدرب فيه ، لتتلقى إشعارًا بالتدريبات المجدولة لهذا اليوم.",
  },
  {
    id: 5,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 281.82 384"
      >
        <path
          class="lineal-stroke"
          d="M182.39 208.78c-16.91 0-26.22 8.48-26.22 23.88v22.07c0 15.41 9.31 23.89 26.22 23.89 16.66 0 26.21-8.71 26.21-23.89v-22.07c0-15.18-9.55-23.88-26.21-23.88zm-4.72 23.88c0-3.32 1.28-4.56 4.72-4.56 4 0 4.87 1.82 4.87 4.56v22.07c0 2.74-.83 4.57-4.87 4.57-3.44 0-4.72-1.24-4.72-4.57zm-10.41-80.31a9.78 9.78 0 00-9.14 5.52l-54.55 112.05a9.87 9.87 0 00-1.08 4.25 11.52 11.52 0 0011.6 11.13c4.21 0 8.07-2.23 9.6-5.51l54.71-112a9.64 9.64 0 00.93-4.25c0-6.67-6.24-11.14-12.07-11.14zm-67.83 6.37c-16.91 0-26.22 8.49-26.22 23.89v22.07c0 15.4 9.31 23.89 26.22 23.89 16.66 0 26.22-8.71 26.22-23.89v-22.07c0-15.18-9.56-23.89-26.22-23.89zm-4.72 23.88c0-3.32 1.28-4.56 4.72-4.56 4 0 4.88 1.83 4.88 4.56v22.08c0 2.74-.83 4.56-4.88 4.56-3.44 0-4.72-1.23-4.72-4.56z"
        />
        <path class="lineal-fill" d="M10.96 10.96h259.9v91.74H10.96z" />
        <path
          class="lineal-stroke"
          d="M270.86 0H11A11 11 0 000 11v362a11 11 0 0011 11h9.88a11 11 0 008.05-3.52L54.28 353l25.38 27.49a11 11 0 0016.1-14.87l-33.44-36.2a11 11 0 00-16.09 0l-24.32 26.31V113.66h238v242.07l-24.31-26.32a11 11 0 00-16.09 0l-30.18 32.68h-10.19L149 329.41a11 11 0 00-16.1 14.87l33.44 36.2a11 11 0 008 3.52h19.77a11 11 0 008-3.52L227.54 353l25.39 27.49A11 11 0 00261 384h9.88a11 11 0 0011-11V11a11 11 0 00-11-11zM21.91 91.75V21.91h238v69.84z"
        />
      </svg>
    ),
    title: "See the latest offers",
    title_ar: "اطلع على أحدث العروض",
    description:
      "See the latest offers on gyms subscriptions and classes from all the gyms around you, and you can subscribe to them and pay online.",
    description_ar:
      "اطلع على أحدث العروض الخاصة باشتراكات الصالات الرياضية ودروسها من جميع الصالات الرياضية من حولك ، ويمكنك الاشتراك فيها والدفع عبر الإنترنت.",
  },
  {
    id: 6,
    src: (
      <svg
        style={{ height: "2.2rem", margin: " 0 1rem" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 324.3 409.6"
      >
        <path
          class="lineal-stroke"
          d="M280 409.6H44.2C19.8 409.6 0 389.8 0 365.4V44.2C0 19.8 19.8 0 44.2 0H280c24.4 0 44.2 19.8 44.2 44.2v240.2c0 6.3-5.1 11.4-11.4 11.4s-11.4-5.1-11.4-11.4V44.2c0-11.9-9.6-21.5-21.5-21.5H44.2c-11.9 0-21.5 9.6-21.5 21.5v321.1c0 11.9 9.6 21.5 21.5 21.5H280c11.9 0 21.5-9.6 21.5-21.5v-24c0-6.3 5.1-11.4 11.4-11.4s11.4 5.1 11.4 11.4v24c-.1 24.5-19.9 44.3-44.3 44.3z"
        />
        <path
          class="lineal-fill"
          d="M252 159.3H72.2c-6.9 0-12.5-5.6-12.5-12.5v-66c0-6.9 5.6-12.5 12.5-12.5H252c6.9 0 12.5 5.6 12.5 12.5v66c0 6.9-5.6 12.5-12.5 12.5z"
        />
        <path
          class="lineal-stroke"
          d="M252 170.7H72.2c-13.2 0-23.9-10.7-23.9-23.9v-66c0-13.2 10.7-23.9 23.9-23.9H252c13.2 0 23.9 10.7 23.9 23.9v66c0 13.2-10.7 23.9-23.9 23.9zm-179.8-91c-.6 0-1.1.5-1.1 1.1v66c0 .6.5 1.1 1.1 1.1H252c.6 0 1.1-.5 1.1-1.1v-66c0-.6-.5-1.1-1.1-1.1H72.2zm131.4 177.4c-6.3 0-11.4-5.1-11.4-11.4 0-3 1.2-5.9 3.3-8l38.1-38.1c4.5-4.3 11.7-4.2 16.1.3 4.2 4.4 4.2 11.3 0 15.7l-38.1 38.1c-2.1 2.2-5 3.4-8 3.4z"
        />
        <path
          class="lineal-stroke"
          d="M241.7 257.1c-3 0-5.9-1.2-8-3.3l-38.1-38.1c-4.4-4.4-4.4-11.7 0-16.1s11.7-4.4 16.1 0l38.1 38.1c4.4 4.4 4.4 11.7 0 16.1-2.2 2.1-5.1 3.3-8.1 3.3zm-113.1 79.5H74.7c-6.3-.2-11.2-5.4-11-11.7.2-6 5-10.9 11-11h53.8c6.3-.2 11.5 4.8 11.7 11 .2 6.3-4.8 11.5-11 11.7h-.6z"
        />
        <path
          class="lineal-stroke"
          d="M101.6 363.6c-6.3 0-11.4-5.1-11.4-11.4v-53.9c0-6.3 5.1-11.4 11.4-11.4 6.3 0 11.4 5.1 11.4 11.4v53.9c0 6.3-5.1 11.4-11.4 11.4zm27-125.5H74.7c-6.3 0-11.4-5.1-11.4-11.4 0-6.3 5.1-11.4 11.4-11.4h53.8c6.3 0 11.4 5.1 11.4 11.4 0 6.3-5.1 11.4-11.3 11.4zm121 81.5h-53.9c-6.3.2-11.5-4.8-11.7-11-.2-6.3 4.8-11.5 11-11.7h54.5c6.3-.2 11.5 4.8 11.7 11 .2 6.3-4.8 11.5-11 11.7h-.6zm0 34.1h-53.9c-6.3 0-11.4-5.1-11.4-11.4s5.1-11.4 11.4-11.4h53.8c6.3 0 11.4 5.1 11.4 11.4s-5.1 11.4-11.3 11.4z"
        />
      </svg>
    ),
    title: "Calculate your BMI",
    title_ar: "احسب مؤشر كتلة الجسم الخاص بك",
    description:
      "Gymme App helps you to calculate your BMI, update your profile, add your gym if not listed, and much more.",
    description_ar:
      "يساعدك تطبيق Gymme على حساب مؤشر كتلة الجسم وتحديث ملفك الشخصي وإضافة صالة الألعاب الرياضية الخاصة بك إذا لم تكن مدرجة وغير ذلك الكثير.",
  },
];

export default MobileFeatures;
