import Navbar from "../Navbar/Navbar";
import { useContext } from "react";
import AppContext from "../../AppContext";

function Privacy() {
  const { translate, systemLang } = useContext(AppContext);
  const t = (k) => translate(k);

  return (
    <>
      <Navbar />
      <section className="wrapper bg-soft-primary">
        <div className="container pt-10 pb-12 pt-md-14 pb-md-16 text-center">
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto">
              <h1 className="display-1 mb-3">{t("Privacy")}</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className={`row gx-0 ${systemLang}`}>
          <aside className="col-xl-3 sidebar sticky-sidebar mt-md-0 pt-8 d-none d-xl-block">
            <div className="widget">
              <nav id="sidebar-nav">
                <ul className="list-unstyled text-reset">
                  <li>
                    <a className="nav-link scroll" href="#PrivacyPolicy">
                      1. Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scroll" href="#Interpretation">
                      2. Interpretation
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scroll" href="#Definitions">
                      3. Definitions
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scroll" href="#PersonalData">
                      4. Personal Data
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scroll" href="#UsageData">
                      5. Usage Data
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scroll"
                      href="#InformationFromThirdPartySocialMediaServices"
                    >
                      6. Information from Third-Party Social Media Services
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scroll"
                      href="#InformationCollectedWhileUsingTheApplication"
                    >
                      7. Information Collected while Using the Application
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scroll"
                      href="#UseOfYourPersonalData"
                    >
                      8. Use of Your Personal Data
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scroll"
                      href="#RetentionOfYourPersonalData"
                    >
                      9. Retention of Your Personal Data
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scroll"
                      href="#TransferOfYourPersonalData"
                    >
                      10. Transfer of Your Personal Data
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scroll"
                      href="#DeleteOfYourPersonalData"
                    >
                      11. Delete of Your Personal Data
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scroll" href="#BusinessTransactions">
                      12. Business Transactions
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scroll" href="#LawEnforcement">
                      13. Law enforcement
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scroll"
                      href="#OtherLegalRequirements"
                    >
                      14. Other legal requirements
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scroll"
                      href="#SecurityOfYourPersonalData"
                    >
                      15. Security of Your Personal Data
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scroll" href="#ChildrenPrivacy">
                      16. Children's Privacy
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scroll" href="#LinksToOtherWebsites">
                      17. Links to Other Websites
                    </a>
                  </li>
                  <li>
                    <a
                      className="nav-link scroll"
                      href="#ChangesToThisPrivacyPolicy"
                    >
                      18. Changes to this Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="nav-link scroll" href="#ContactUs">
                      19. Contact Us
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </aside>
          <div className="col-xl-8">
            <section id="PrivacyPolicy" className="wrapper pt-16">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">1. Privacy Policy</h2>
                  <span>Last updated: January 26, 2022</span>
                  <p>
                    his Privacy Policy describes Our policies and procedures on
                    the collection, use and disclosure of Your information when
                    You use the Service and tells You about Your privacy rights
                    and how the law protects You.
                  </p>
                  <p>
                    We use Your Personal data to provide and improve the
                    Service. By using the Service, You agree to the collection
                    and use of information in accordance with this Privacy
                    Policy. This Privacy Policy has been created with the help
                    of the Privacy Policy Template.
                  </p>
                </div>
              </div>
            </section>
            <section id="Interpretation" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">2. Interpretation</h2>
                  <p>
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. The
                    following definitions shall have the same meaning regardless
                    of whether they appear in singular or in plural.
                  </p>
                </div>
              </div>
            </section>
            <section id="Definitions" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">3. Definitions</h2>
                  <p>For the purposes of this Privacy Policy:</p>
                  <ul className="icon-list bullet-bg bullet-soft-primary">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Account</span> means a unique account created for
                      You to access our Service or parts of our Service.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Affiliate</span> means an entity that controls, is
                      controlled by or is under common control with a party,
                      where "control" means ownership of 50% or more of the
                      shares, equity interest or other securities entitled to
                      vote for election of directors or other managing
                      authority.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Application</span> means the software program
                      provided by the Company downloaded by You on any
                      electronic device, named Gymme
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Company</span> (referred to as either "the Company",
                      "We", "Us" or "Our" in this Agreement) refers to Gymme,
                      296 7th Street - 2nd Neighborhood - 3rd District - 6th of
                      October.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Device</span> means any device that can access the
                      Service such as a computer, a cellphone or a digital
                      tablet.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Personal Data</span> any information that relates to
                      an identified or identifiable individual.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Service</span> refers to the Application.t
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Service Provider</span> means any natural or legal
                      person who processes the data on behalf of the Company. It
                      refers to third-party companies or individuals employed by
                      the Company to facilitate the Service, to provide the
                      Service on behalf of the Company, to perform services
                      related to the Service or to assist the Company in
                      analyzing how the Service is used.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Third-party Social Media Service</span> refers to
                      any website or any social network website through which a
                      User can log in or create an account to use the Service.{" "}
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>Usage Data</span> refers to data collected
                      automatically, either generated by the use of the Service
                      or from the Service infrastructure itself (for example,
                      the duration of a page visit).
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>You</span> means the individual accessing or using
                      the Service, or the company, or other legal entity on
                      behalf of which such individual is accessing or using the
                      Service, as applicable.
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section id="PersonalData" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">4. Personal Data</h2>
                  <p>
                    While using Our Service, We may ask You to provide Us with
                    certain personally identifiable information that can be used
                    to contact or identify You. Personally identifiable
                    information may include, but is not limited to:
                  </p>
                  <ul className="icon-list bullet-bg bullet-soft-primary">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Email address
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      First name and last name
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Phone number
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Address, State, Province, ZIP/Postal code, City
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Usage Data
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section id="UsageData" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">5. Usage Data</h2>
                  <p>
                    Usage Data is collected automatically when using the
                    Service.
                  </p>
                  <p>
                    Usage Data may include information such as Your Device's
                    Internet Protocol address (e.g. IP address), browser type,
                    browser version, the pages of our Service that You visit,
                    the time and date of Your visit, the time spent on those
                    pages, unique device identifiers and other diagnostic data.
                  </p>
                  <p>
                    When You access the Service by or through a mobile device,
                    We may collect certain information automatically, including,
                    but not limited to, the type of mobile device You use, Your
                    mobile device unique ID, the IP address of Your mobile
                    device, Your mobile operating system, the type of mobile
                    Internet browser You use, unique device identifiers and
                    other diagnostic data.
                  </p>
                  <p>
                    We may also collect information that Your browser sends
                    whenever You visit our Service or when You access the
                    Service by or through a mobile device.
                  </p>
                </div>
              </div>
            </section>
            <section
              id="InformationFromThirdPartySocialMediaServices"
              className="wrapper pt-8"
            >
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">
                    6. Information from Third-Party Social Media Services
                  </h2>
                  <p>
                    The Company allows You to create an account and log in to
                    use the Service through the following Third-party Social
                    Media Services:
                  </p>
                  <ul className="icon-list bullet-bg bullet-soft-primary">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Google
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Facebook
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Twitter
                    </li>
                  </ul>
                  <p>
                    If You decide to register through or otherwise grant us
                    access to a Third-Party Social Media Service, We may collect
                    Personal data that is already associated with Your
                    Third-Party Social Media Service's account, such as Your
                    name, Your email address, Your activities or Your contact
                    list associated with that account.
                  </p>
                  <p>
                    You may also have the option of sharing additional
                    information with the Company through Your Third-Party Social
                    Media Service's account. If You choose to provide such
                    information and Personal Data, during registration or
                    otherwise, You are giving the Company permission to use,
                    share, and store it in a manner consistent with this Privacy
                    Policy.
                  </p>
                </div>
              </div>
            </section>
            <section
              id="InformationCollectedWhileUsingTheApplication"
              className="wrapper pt-8"
            >
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">
                    7. Information Collected while Using the Application
                  </h2>
                  <p>
                    While using Our Application, in order to provide features of
                    Our Application, We may collect, with Your prior permission:
                  </p>
                  <ul className="icon-list bullet-bg bullet-soft-primary">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Information regarding your location
                    </li>
                  </ul>
                  <p>
                    We use this information to provide features of Our Service,
                    to improve and customize Our Service. The information may be
                    uploaded to the Company's servers and/or a Service
                    Provider's server or it may be simply stored on Your device.
                  </p>
                  <p>
                    You can enable or disable access to this information at any
                    time, through Your Device settings.
                  </p>
                </div>
              </div>
            </section>
            <section id="UseOfYourPersonalData" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">8. Use of Your Personal Data</h2>
                  <p>
                    The Company may use Personal Data for the following
                    purposes:
                  </p>
                  <ul className="icon-list bullet-bg bullet-soft-primary">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>To provide and maintain our Service,</span>{" "}
                      including to monitor the usage of our Service.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>To manage Your Account:</span> to manage Your
                      registration as a user of the Service. The Personal Data
                      You provide can give You access to different
                      functionalities of the Service that are available to You
                      as a registered user.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>For the performance of a contract:</span> the
                      development, compliance and undertaking of the purchase
                      contract for the products, items or services You have
                      purchased or of any other contract with Us through the
                      Service.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>To contact You:</span> To contact You by email,
                      telephone calls, SMS, or other equivalent forms of
                      electronic communication, such as a mobile application's
                      push notifications regarding updates or informative
                      communications related to the functionalities, products or
                      contracted services, including the security updates, when
                      necessary or reasonable for their implementation.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>To provide You</span> with news, special offers and
                      general information about other goods, services and events
                      which we offer that are similar to those that you have
                      already purchased or enquired about unless You have opted
                      not to receive such information.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>To manage Your requests:</span> To attend and manage
                      Your requests to Us.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>For business transfers:</span> We may use Your
                      information to evaluate or conduct a merger, divestiture,
                      restructuring, reorganization, dissolution, or other sale
                      or transfer of some or all of Our assets, whether as a
                      going concern or as part of bankruptcy, liquidation, or
                      similar proceeding, in which Personal Data held by Us
                      about our Service users is among the assets transferred.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>For other purposes:</span>: We may use Your
                      information for other purposes, such as data analysis,
                      identifying usage trends, determining the effectiveness of
                      our promotional campaigns and to evaluate and improve our
                      Service, products, services, marketing and your
                      experience.
                    </li>
                  </ul>
                  <p>
                    We may share Your personal information in the following
                    situations:
                  </p>
                  <ul className="icon-list bullet-bg bullet-soft-primary">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>With Service Providers:</span> We may share Your
                      personal information with Service Providers to monitor and
                      analyze the use of our Service, to contact You.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>For business transfers:</span> We may share or
                      transfer Your personal information in connection with, or
                      during negotiations of, any merger, sale of Company
                      assets, financing, or acquisition of all or a portion of
                      Our business to another company.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>With Affiliates:</span> We may share Your
                      information with Our affiliates, in which case we will
                      require those affiliates to honor this Privacy Policy.
                      Affiliates include Our parent company and any other
                      subsidiaries, joint venture partners or other companies
                      that We control or that are under common control with Us.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>With business partners:</span> We may share Your
                      information with Our business partners to offer You
                      certain products, services or promotions.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>With other users:</span> when You share personal
                      information or otherwise interact in the public areas with
                      other users, such information may be viewed by all users
                      and may be publicly distributed outside. If You interact
                      with other users or register through a Third-Party Social
                      Media Service, Your contacts on the Third-Party Social
                      Media Service may see Your name, profile, pictures and
                      description of Your activity. Similarly, other users will
                      be able to view descriptions of Your activity, communicate
                      with You and view Your profile.
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      <span>With Your consent:</span> We may disclose Your
                      personal information for any other purpose with Your
                      consent.
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section id="RetentionOfYourPersonalData" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">9. Retention of Your Personal Data</h2>
                  <p>
                    The Company will retain Your Personal Data only for as long
                    as is necessary for the purposes set out in this Privacy
                    Policy. We will retain and use Your Personal Data to the
                    extent necessary to comply with our legal obligations (for
                    example, if we are required to retain your data to comply
                    with applicable laws), resolve disputes, and enforce our
                    legal agreements and policies.
                  </p>
                  <p>
                    The Company will also retain Usage Data for internal
                    analysis purposes. Usage Data is generally retained for a
                    shorter period of time, except when this data is used to
                    strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data
                    for longer time periods.
                  </p>
                </div>
              </div>
            </section>
            <section id="TransferOfYourPersonalData" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">10. Transfer of Your Personal Data</h2>
                  <p>
                    Your information, including Personal Data, is processed at
                    the Company's operating offices and in any other places
                    where the parties involved in the processing are located. It
                    means that this information may be transferred to — and
                    maintained on — computers located outside of Your state,
                    province, country or other governmental jurisdiction where
                    the data protection laws may differ than those from Your
                    jurisdiction.
                  </p>
                  <p>
                    Your consent to this Privacy Policy followed by Your
                    submission of such information represents Your agreement to
                    that transfer.
                  </p>
                  <p>
                    The Company will take all steps reasonably necessary to
                    ensure that Your data is treated securely and in accordance
                    with this Privacy Policy and no transfer of Your Personal
                    Data will take place to an organization or a country unless
                    there are adequate controls in place including the security
                    of Your data and other personal information.
                  </p>
                </div>
              </div>
            </section>
            <section id="DeleteOfYourPersonalData" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">11. Delete of Your Personal Data</h2>
                  <p>
                    Your can Delete Your Personal Data in the following ways:
                    using the “Delete Account” button in the profile page; or by
                    contacting us using the contact information provided in this
                    Privacy Policy.
                  </p>
                  <p>
                    your account will be deactivated. When deactivated, your
                    Gymme account, including your display name, username, and
                    public profile, will no longer be viewable on Gymme for iOS,
                    and Gymme for Android. For up to 30 days after deactivation
                    it is still possible to restore your Gymme account if it was
                    accidentally or wrongfully deactivated.
                  </p>
                </div>
              </div>
            </section>
            <section id="BusinessTransactions" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">12. Business Transactions</h2>
                  <p>
                    If the Company is involved in a merger, acquisition or asset
                    sale, Your Personal Data may be transferred. We will provide
                    notice before Your Personal Data is transferred and becomes
                    subject to a different Privacy Policy.
                  </p>
                </div>
              </div>
            </section>
            <section id="LawEnforcement" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">13. Law enforcement</h2>
                  <p>
                    Under certain circumstances, the Company may be required to
                    disclose Your Personal Data if required to do so by law or
                    in response to valid requests by public authorities (e.g. a
                    court or a government agency).
                  </p>
                </div>
              </div>
            </section>
            <section id="OtherLegalRequirements" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">14. Other legal requirements</h2>
                  <p>
                    The Company may disclose Your Personal Data in the good
                    faith belief that such action is necessary to:
                  </p>
                  <ul className="icon-list bullet-bg bullet-soft-primary">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Comply with a legal obligation
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Protect and defend the rights or property of the Company
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Prevent or investigate possible wrongdoing in connection
                      with the Service
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Protect the personal safety of Users of the Service or the
                      public
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      Protect against legal liability
                    </li>
                  </ul>
                </div>
              </div>
            </section>
            <section id="SecurityOfYourPersonalData" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">15. Security of Your Personal Data</h2>
                  <p>
                    The security of Your Personal Data is important to Us, but
                    remember that no method of transmission over the Internet,
                    or method of electronic storage is 100% secure. While We
                    strive to use commercially acceptable means to protect Your
                    Personal Data, We cannot guarantee its absolute security
                  </p>
                </div>
              </div>
            </section>
            <section id="ChildrenPrivacy" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">16. Children's Privacy</h2>
                  <p>
                    TOur Service does not address anyone under the age of 13. We
                    do not knowingly collect personally identifiable information
                    from anyone under the age of 13. If You are a parent or
                    guardian and You are aware that Your child has provided Us
                    with Personal Data, please contact Us. If We become aware
                    that We have collected Personal Data from anyone under the
                    age of 13 without verification of parental consent, We take
                    steps to remove that information from Our servers.
                  </p>
                  <p>
                    TIf We need to rely on consent as a legal basis for
                    processing Your information and Your country requires
                    consent from a parent, We may require Your parent's consent
                    before We collect and use that information.
                  </p>
                </div>
              </div>
            </section>
            <section id="LinksToOtherWebsites" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">17. Links to Other Websites</h2>
                  <p>
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third party link, You will
                    be directed to that third party's site. We strongly advise
                    You to review the Privacy Policy of every site You visit.
                  </p>
                  <p>
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </p>
                </div>
              </div>
            </section>
            <section id="ChangesToThisPrivacyPolicy" className="wrapper pt-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">18. Changes to this Privacy Policy</h2>
                  <p>
                    We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy
                    on this page.
                  </p>
                  <p>
                    We will let You know via email and/or a prominent notice on
                    Our Service, prior to the change becoming effective and
                    update the "Last updated" date at the top of this Privacy
                    Policy.
                  </p>
                  <p>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </p>
                </div>
              </div>
            </section>
            <section id="ContactUs" className="wrapper py-8">
              <div className="card">
                <div className="card-body p-10">
                  <h2 className="mb-3">19. Contact Us</h2>
                  <p>
                    If you have any questions about this Privacy Policy, You can
                    contact us:
                  </p>
                  <ul className="icon-list bullet-bg bullet-soft-primary">
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      By email:{" "}
                      <a href="mailto:support@gymmeclub.com">
                        support@gymmeclub.com
                      </a>
                    </li>
                    <li>
                      <span>
                        <i className="uil uil-check"></i>
                      </span>
                      By visiting this page on our website:{" "}
                      <a href="https://gymmeclub.com/">
                        https://gymmeclub.com/
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Privacy;
