import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/red.css";
import bg12Img from "../assets/img/photos/bg12.jpg";
import about7Img from "../assets/img/imags/about7.jpg";
import about7Img2x from "../assets/img/imags/about7@2x.jpg";
import bg1 from "../assets/img/imags/bg1.jpg";
import { ReactComponent as Target } from "../assets/img/icons/lineal/target.svg";
import { ReactComponent as Award } from "../assets/img/icons/lineal/award-2.svg";
import co1 from "../assets/img/photos/co1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import Customers from "../DATA/CUSTOMERS";
import Navbar from "../Navbar/Navbar";
import { useContext } from "react";
import AppContext from "../../AppContext";

function About() {
  const { translate, systemLang } = useContext(AppContext);
  const t = (k) => translate(k);

  return (
    <>
      <Navbar />
      <div className="wrapper bg-gray">
        <div className="container pt-10 pt-md-14 text-center">
          <div className="row">
            <div className="col-xl-6 mx-auto">
              <h1
                className="display-1 mb-4"
                style={{ direction: systemLang === "ar" ? "rtl" : "ltr" }}
              >
                {t("about_title")}
              </h1>
              <p className="lead fs-lg mb-0">{t("about_Team")}</p>
            </div>
          </div>
        </div>
        <figure
          style={{
            bottom: "0",
            left: "0",
            zIndex: "2",
          }}
        >
          <img src={bg12Img} alt="" />
        </figure>
      </div>
      <section className={`wrapper bg-light ${systemLang}`}>
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-md-8 col-lg-6 col-xl-5 order-lg-2 position-relative">
              <div
                className="shape bg-soft-primary rounded-circle relax w-20 h-20"
                style={{
                  top: "-2rem",
                  right: "-1.9rem",
                }}
              ></div>
              <figure className="rounded">
                <img src={about7Img} srcSet={about7Img2x} alt="" />
              </figure>
            </div>
            <div className="col-lg-6">
              <h2 className="display-4 mb-3">{t("about_solutions")}</h2>
              <p className="mb-6">
                {t("about_platform")}
                <br />
                <br />
                {t("about_helping")}
                <br />
                <br />
                {t("about_vision")}
              </p>
              <div className="row gx-xl-10 gy-6">
                <div className="col-md-6">
                  <div className="d-flex flex-row">
                    <div>
                      <Target
                        className="svg-inject icon-svg icon-svg-sm me-4"
                        alt=""
                      />
                    </div>
                    <div>
                      <h4 className="mb-1">{t("about_Mission")}</h4>
                      <p className="mb-0">{t("about_Mission_description")}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-row">
                    <div>
                      <Award
                        className="svg-inject icon-svg icon-svg-sm me-4"
                        alt=""
                      />
                    </div>
                    <div>
                      <h4 className="mb-1">{t("about_Values")}</h4>
                      <p className="mb-0">{t("about_Values_description")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="wrapper image-wrapper bg-image bg-overlay"
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className={`container py-18 ${systemLang}`}>
          <div className="row">
            <div className="col-lg-8">
              <h2 className="fs-16 text-uppercase text-line text-white mb-3">
                {t("about_Gymme")}
              </h2>
              <h3 className="display-4 mb-6 text-white pe-xxl-18">
                {t("about_dreaming")}
              </h3>
            </div>
          </div>
        </div>
      </section>
      {/* <section className={`wrapper bg-soft-primary ${systemLang}`}>
        <div className="container pt-16 pb-14 pb-md-0">
          <div className="row gx-lg-8 gx-xl-0 align-items-center">
            <div className="col-md-5 col-lg-5 col-xl-4 offset-xl-1 d-none d-md-flex position-relative align-self-end">
              <div
                className="shape rounded-circle bg-pale-primary relax w-21 h-21 d-md-none d-lg-block"
                style={{
                  top: "7rem",
                  left: "1rem",
                }}
              ></div>
              <figure>
                <img src={co1} alt="" />
              </figure>
            </div>
            <div
              className={`col-md-7 col-lg-6 col-xl-6 col-xxl-5 offset-xl-1 ${systemLang}`}
            >
              <div
                className={`swiper-container dots-start dots-closer mt-md-10 mb-md-15 ${systemLang}`}
              >
                <>
                  <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    pagination={true}
                    modules={[Pagination]}
                    className={`swiper-wrapper ${systemLang}`}
                  >
                    {Customers.map((x, index) => (
                      <SwiperSlide key={index} className="en">
                        <div className={`swiper-slide ${systemLang}`}>
                          <blockquote className="icon fs-lg">
                            <p>
                              {systemLang === "ar"
                                ? x.description_ar
                                : x.description}
                            </p>
                            <div className="blockquote-details">
                              <div className="info ps-0">
                                <h5 className="mb-1">
                                  {systemLang === "ar" ? x.title_ar : x.title}
                                </h5>
                                <p className="mb-5">
                                  {x.name}
                                </p>
                              </div>
                            </div>
                          </blockquote>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default About;
