import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import Logo from "../assets/img/logo.png";
import LogoLight from "../assets/img/logo-light.png";
import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/red.css";
import Config from "../../Config";
import AppContext from "../../AppContext";
import Info from "./Info";

function Nav_1() {
  const {translate ,changeLanguage ,systemLang} = useContext(AppContext)
  const t = k => translate(k)
  
  const [header, setHeader] = useState("header-home");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header-home");
    } else if (window.scrollY > 270) {
      return setHeader("header-home2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <>
      <header className={`wrapper bg-soft-primary ${systemLang}`}>
        <nav
          className={`${header} navbar navbar-expand-lg center-nav transparent navbar-dark caret-none ${systemLang}`}
        >
          <div
            className={`container flex-lg-row flex-nowrap align-items-center ${systemLang}`}
          >
            <div className="navbar-brand w-100">
              <Link to="/">
                <img className="logo-dark" src={Logo} alt="" />
                <img className="logo-light" src={LogoLight} alt="" />
              </Link>
            </div>
            <div
              className={`navbar-collapse offcanvas offcanvas-nav offcanvas-start ${systemLang}`}
              tabIndex="-1"
              id="offcanvasExample"
              aria-labelledby="offcanvasExampleLabel"
            >
              <div className="offcanvas-header d-lg-none">
                <h3 className="text-white fs-30 mb-0">Gymme</h3>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body d-flex flex-column h-100 e">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-link">
                      {t("home")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Gyms" className="nav-link">
                      {t("Gyms")}
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link to="/Mobile" className="nav-link">
                      {t("Mobile")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Contact" className="nav-link">
                      {t("contact")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/Careers" className="nav-link">
                      {t("Careers")}
                    </Link>
                  </li>
                </ul>
                <div className="offcanvas-footer d-lg-none">
                  <div>
                    <a
                      href="mailto:info@gymmeclub.com"
                      className="link-inverse"
                    >
                      info@gymmeclub.com
                    </a>
                    <br /> 00 (123) 456 78 90 <br />
                    <nav className="nav social social-white mt-4">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={Config?.socialMediaLinks?.twitter}
                      >
                        <i className="uil uil-twitter"></i>
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={Config?.socialMediaLinks?.facebook}
                      >
                        <i className="uil uil-facebook-f"></i>
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={Config?.socialMediaLinks?.linkedin}
                      >
                        <i className="uil uil-linkedin"></i>
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={Config?.socialMediaLinks?.insta}
                      >
                        <i className="uil uil-instagram"></i>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`navbar-other w-100 d-flex justify-content-end ${systemLang}`}
            >
              <ul className="navbar-nav flex-row align-items-center justify-content-end">
                <li className="nav-item">
                  <a
                    href="#!"
                    className="nav-link"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                  >
                    <i className="uil uil-info-circle"></i>
                  </a>
                </li>
                <select
                  value={systemLang}
                  onChange={(e) => {
                    changeLanguage(systemLang === "en" ? "ar" : "en");
                  }}
                  className="nav-item dropdown language-select text-uppercase"
                >
                  <option value="en" className="nav-item">
                    <Link className="dropdown-item" to="#!">
                      {t("En")}
                    </Link>
                  </option>
                  <option value="ar" className="nav-item">
                    <Link className="dropdown-item" to="#!">
                      {t("Ar")}
                    </Link>
                  </option>
                </select>
                <li className="nav-item d-lg-none">
                  <button
                    className="hamburger offcanvas-nav-btn"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                  >
                    <span></span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Info />
        <div
          className="offcanvas offcanvas-top bg-light"
          id="offcanvas-search"
          data-bs-scroll="true"
        >
          <div className="container d-flex flex-row py-6">
            <form className="search-form w-100">
              <input
                id="search-form"
                type="text"
                className="form-control"
                placeholder="Type keyword and hit enter"
              />
            </form>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </header>
    </>
  );
}

export default Nav_1;
