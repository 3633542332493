import { useContext } from "react";
import { Link } from "react-router-dom";
import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/red.css";
import about2 from "../assets/img/imags/about2.jpg";
import about22x from "../assets/img/imags/about2@2x.jpg";
import about3 from "../assets/img/imags/about3.jpg";
import about32x from "../assets/img/imags/about3@2x.jpg";
import { ReactComponent as Megaphone } from "../assets/img/icons/lineal/megaphone.svg";
import bg1 from "../assets/img/imags/bg1.jpg";
import bg7 from "../assets/img/imags/bg7.jpg";
import bg8 from "../assets/img/imags/bg8.jpg";
import bg9 from "../assets/img/imags/bg9.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";
import Nav from "../Navbar/Nav";
import Config from "../../Config";
import AppContext from "../../AppContext";

function Home() {
  const { translate, systemLang } = useContext(AppContext);
  const t = (k) => translate(k);

  return (
    <div className="home">
      <Nav />
      <section className="wrapper bg-dark">
        <div
          className="swiper-container swiper-hero dots-over"
          data-margin="0"
          data-autoplay="true"
          data-autoplaytime="5000"
          data-nav="true"
          data-dots="true"
          data-items="1"
        >
          <>
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              pagination={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="swiper-wrapper"
            >
              <SwiperSlide>
                <div
                  className="swiper-slide bg-overlay bg-overlay-400 bg-dark bg-image"
                  // data-image-src="./assets/img/photos/bg9.jpg"
                  style={{ backgroundImage: `url(${bg9})` }}
                >
                  <div className="container h-100">
                    <div className="row h-100">
                      <div className="col-md-10 offset-md-1 col-lg-7 offset-lg-5 col-xl-6 offset-xl-6 col-xxl-5 offset-xxl-6 text-center text-lg-start justify-content-center align-self-center align-items-start">
                        <h2
                          className={`${
                            systemLang === "ar" ? "ar" : "en"
                          } display-1 fs-56 mb-4 text-white animate__animated animate__slideInDown animate__delay-1s`}
                        >
                          {t("home_swiper_three_title")}
                        </h2>
                        <p
                          className={`${
                            systemLang === "ar" ? "ar" : "en"
                          } lead fs-23 lh-sm mb-7 text-white animate__animated animate__slideInRight animate__delay-2s`}
                        >
                          {t("home_swiper_three_description")}
                        </p>
                        <div className="animate__animated animate__slideInUp animate__delay-3s">
                          <Link
                            to="/Register"
                            className="btn btn-lg btn-primary rounded-pill"
                          >
                            {t("home_swiper_three_button")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide bg-overlay bg-overlay-400 bg-dark bg-image"
                  // data-image-src="./assets/img/photos/bg8.jpg"
                  style={{ backgroundImage: `url(${bg8})` }}
                >
                  <div className="container h-100">
                    <div className="row h-100">
                      <div className="col-md-11 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center justify-content-center align-self-center">
                        <h2
                          className={`${
                            systemLang === "ar" ? "ar" : "en"
                          } display-1 fs-56 mb-4 text-white animate__animated animate__slideInDown animate__delay-1s`}
                        >
                          {t("home_swiper_two_title")}
                        </h2>
                        <p
                          className={`${
                            systemLang === "ar" ? "ar" : "en"
                          } lead fs-23 lh-sm mb-7 text-white animate__animated animate__slideInRight animate__delay-2s`}
                        >
                          {t("home_swiper_two_description")}
                        </p>
                        <div
                          className="d-flex justify-content-center justify-content-lg-center"
                          // data-cues="slideInDown"
                          // data-group="download"
                        >
                          <span>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={Config.userIosApp}
                              className="btn btn-primary btn-icon btn-icon-start rounded me-2"
                            >
                              <i className="uil uil-apple"></i> {t("downApp")}
                            </a>
                          </span>
                          <span>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={Config.userAndroidApp}
                              className="btn btn-primary btn-icon btn-icon-start rounded"
                            >
                              <i className="uil uil-google-play"></i>{" "}
                              {t("downGoogle")}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div
                  className="swiper-slide bg-overlay bg-overlay-400 bg-dark bg-image"
                  // data-image-src="./assets/img/photos/bg7.jpg"
                  style={{ backgroundImage: `url(${bg7})` }}
                >
                  <div className="container h-100">
                    <div className="row h-100">
                      <div className="col-md-10 offset-md-1 col-lg-7 offset-lg-0 col-xl-6 col-xxl-5 text-center text-lg-start justify-content-center align-self-center align-items-start">
                        <h2
                          className={`${
                            systemLang === "ar" ? "ar" : "en"
                          } display-1 fs-56 mb-4 text-white animate__animated animate__slideInDown animate__delay-1s`}
                        >
                          {t("home_swiper_one_title")}
                        </h2>
                        <p
                          className={`${
                            systemLang === "ar" ? "ar" : "en"
                          } lead fs-23 lh-sm mb-7 text-white animate__animated animate__slideInRight animate__delay-2s`}
                        >
                          {t("home_swiper_one_description")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </>
        </div>
      </section>
      <div className={`wrapper bg-light angled lower-end ${systemLang}`}>
        <div className="container py-14 py-md-16">
          <div className="row gx-lg-8 gx-xl-12 gy-10 mb-14 mb-md-17 align-items-center">
            <div className="col-lg-6 position-relative order-lg-2">
              <div
                className="shape bg-dot primary relax w-16 h-20"
                data-relax-speed="1"
                style={{ top: "3rem", left: "5.5rem" }}
              ></div>
              <div className="overlap-grid overlap-grid-2">
                <div className="item">
                  <figure className="rounded shadow">
                    <img src={about2} srcSet={about22x} alt="" />
                  </figure>
                </div>
                <div className="item">
                  <figure className="rounded shadow">
                    <img src={about3} srcSet={about32x} alt="" />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Megaphone
                className="svg-inject icon-svg icon-svg-md mb-4"
                alt=""
              />
              <h2 className="display-4 mb-3">{t("home_title")}</h2>
              <p className="lead fs-lg">
                {t("home_Gymme")}
                <br />
              </p>
              <p className="mb-2 fs-18">{t("home_vision")}</p>
              <p className="mb-6 fs-18">{t("home_helping")}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`wrapper image-wrapper bg-image bg-overlay ${systemLang}`}
        style={{ backgroundImage: `url(${bg1})` }}
      >
        <div className="container py-18">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="fs-16 text-uppercase text-line text-white mb-3">
                {t("home_Community")}
              </h2>
              <h3 className="display-4 mb-6 text-white pe-xxl">
                {t("home_business")}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
