import { md5 } from "hash-wasm";
import {TRANSLATIONS_ar as ArWords} from "./Components/translations/arab/translation"
import {TRANSLATIONS_en as EnWords} from "./Components/translations/en/translation"

const isProduction = process.env.NODE_ENV === "production";
const Config = {
  apiBaseUrl: isProduction
    ? "https://backend.gymmeclub.com/api"
    : "https://staging-backend.gymmeclub.com/api",
  getHashForPrintToken: () => {
    const currentDate = new Date();
    const month =
      currentDate.getMonth() + 1 < 10
        ? `0${currentDate.getMonth() + 1}`
        : currentDate.getMonth() + 1;
    const date =
      currentDate.getDate() < 10
        ? `0${currentDate.getDate()}`
        : currentDate.getDate();
    const hash = `${currentDate.getFullYear()}-${month}-${date}-print-token-auth`;
    return md5(hash);
  },
  boardSignInUrl: "https://my.gymmeclub.com",
  employeeAndroidApp:
    "https://play.google.com/store/apps/details?id=com.gymmecompany.gymmeteamapp",
  userAndroidApp:
    "https://play.google.com/store/apps/details?id=com.gymmecompany.gymmeuserapp",
  employeeIosApp: "https://apps.apple.com/eg/app/gymme-team/id1614624161",
  userIosApp: "https://apps.apple.com/eg/app/gymme-lets-team-up/id1616218504",
  socialMediaLinks: {
    twitter: "https://www.twitter.com/gymmeclub",
    facebook: "https://www.facebook.com/gymmeclub",
    linkedin: "https://www.linkedin.com/company/gymmeclub",
    insta: "https://www.instagram.com/gymmeclub",
    fbCommunity: "https://www.facebook.com/groups/gymmecommunity/",
  },
  changeLayout: (lang) => {
    if (lang === "ar") {
      document.querySelectorAll(".en").forEach((el) => {
        el.classList.add("ar");
        el.classList.remove("en");
      });
    } else {
      document.querySelectorAll(".ar").forEach((el) => {
        el.classList.add("en");
        el.classList.remove("ar");
      });
    }
  },
  phones: ["00201003588100", "00201091191010"],
  emails: ["info@gymmeclub.com", "support@gymmeclub.com"],
  translateByKey: (key, lang) => {
    if (lang === "ar") return ArWords[key];
    return EnWords[key];
  },
};

export default Config;
