import { useContext } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Link } from 'react-router-dom'
import AppContext from '../../../AppContext'

const RegisterAlert = ({message = undefined}) => {
    const {translate} = useContext(AppContext)
    const t = k => translate(k)

    return (
        <SweetAlert
            custom
            customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
            title={message ? message : t("RegisterDone")}
            customButtons={
                <>
                  <Link className='nav-link' to="/"> {t("home")} </Link>
                  <Link className='nav-link' to="/Gyms"> {t("Gyms")} </Link>
                </>
            }
        />
    )
}

export default RegisterAlert