import { useContext } from "react";
import AppContext from "../../AppContext";

const GymForm = ({data ,setData ,errors}) => {
  const {translate} = useContext(AppContext)
  const t = k => translate(k)

  return (
    <>
      <form onSubmit={e => e.preventDefault()} className="contact-form needs-validation">
        <div className="messages"></div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-floating mb-4">
              <input className="form-control"
                onChange={e => setData('gym_name' ,e.target.value)}
                value={data.gym_name}
                placeholder="Jane"
              />
              <label htmlFor="name">{t("contact_Name")}</label>
              {errors.gym_name ? <div className="invalid-feedback">{errors.gym_name}</div> : null}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-floating mb-4 en">
              <input type="number"
                className="form-control en"
                placeholder="01154258770"
                onChange={e => setData('gym_phone' ,e.target.value)}
                value={data?.gym_phone}
              />
              <label htmlFor="phone" className="en"> {t("contact_Phone")} </label>
              {errors.gym_phone ? <div className="invalid-feedback">{errors.gym_phone}</div> : null}
            </div>
          </div>
          <div className="col-md-12 en">
            <div className="form-floating mb-4 en">
              <input className="form-control en"
                placeholder="Last of st...."
                onChange={e => setData('gym_address' ,e.target.value)}
                value={data?.gym_address}
              />
              <label htmlFor="address" className="en"> {t("address")} </label>
              {errors.gym_address ? <div className="invalid-feedback">{errors.gym_address}</div> : null}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default GymForm
