import { BrowserRouter, Routes, Route } from "react-router-dom"
import React from "react"
import "./App.css"
import Home from "./Components/pages/Home"
import Mobile from "./Components/pages/Mobile"
import Gyms from "./Components/pages/Gyms"
import About from "./Components/pages/About"
import Contact from "./Components/pages/Contact"
import Privacy from "./Components/pages/Privacy"
import Terms from "./Components/pages/Terms"
import Careers from "./Components/pages/Careers"
import Register from "./Components/pages/Register"
import ScrollToTop from "./Components/pages/ScrollToTop"
import Description from "./Components/pages/Job-description"
import Top from "./Components/pages/backToTop"
import Footer from "./Components/Footer/Footer"

const AppTemplate = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path="/Mobile"
                    element={<Mobile />}
                />
                <Route
                    path="/Gyms"
                    element={<Gyms />}
                />
                <Route
                    path="/About"
                    element={<About />}
                />
                <Route
                    path="/Contact"
                    element={<Contact />}
                />
                <Route
                    path="/Privacy"
                    element={<Privacy />}
                />
                <Route
                    path="/Terms"
                    element={<Terms />}
                />
                <Route
                    path="/Careers"
                    exact
                    element={<Careers />}
                />
                <Route
                    path="/Register"
                    exact
                    element={<Register />}
                />
                <Route
                    path="/Job-description/:id"
                    exact
                    element={<Description />}
                />
            </Routes>
            <Footer/>
            <Top />
        </BrowserRouter>
    )
}

export default AppTemplate
