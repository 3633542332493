import { useContext, useState } from "react"
import axios from "axios"

import "../assets/css/plugins.css"
import "../assets/css/style.css"
import "../assets/css/colors/red.css"

import Config from "../../Config"
import Spinner from "../Shared/Spinner"
import AppContext from "../../AppContext"

function ContactForm() {
  const {translate} = useContext(AppContext)

  const [successMessage, setSuccessMessage] = useState("")
  const [contactData ,setContactData] = useState({name: '' ,email: '' ,subject: '' ,phone: '' ,description: ''})
  const [errors ,setErrors] = useState({name: '' ,email: '' ,subject: '' ,phone: '' ,description: ''})
  const [disabled ,setDisabled] = useState(false)

  const formUrl = Config.apiBaseUrl + "/landing-page/landing/storeFromLanding"
  
  const t = k => translate(k)

  const changeAttr = (attrName ,attrValue) => {
    const tempData = {...contactData}
    tempData[attrName] = attrValue
    setContactData(tempData)
    const tempErrors = {...errors}
    tempErrors[attrName] = ''
    setErrors(tempErrors)
  }

  const sendContact = () => {
    setDisabled(true)
    Config.getHashForPrintToken().then((result) => {
      axios
      .post(`${Config.apiBaseUrl}/print-token`, {hash: result, type: "landing"})
      .then((response) => {
        executeForm(response?.data?.token)
      })
      .catch((err) => {
        alert.error("Something wrong happened, Please Try again Later")
      })
    })
    
  }

  const executeForm = token => {
    axios.post(`${formUrl}?token=${token}` ,contactData)
    .then(response => {
      setSuccessMessage(response?.data?.message)
      setDisabled(false)
      setErrors({name: '' ,email: '' ,subject: '' ,phone: '' ,description: ''})
      setContactData({name: '' ,email: '' ,subject: '' ,phone: '' ,description: ''})
      setTimeout(() => setSuccessMessage('') ,3000)
    })
    .catch(err => {
      const tempErrors = {...errors} ,_errors = err.response?.data?.errors
      Object.keys(_errors)?.map?.(key => tempErrors[key] = _errors[key]?.join?.(","))
      setErrors(tempErrors)
      setDisabled(false)
    })
  }

  return (
    <>
      <form onSubmit={e => e.preventDefault()} className="contact-form needs-validation" noValidate>
        <div className="messages"></div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-floating mb-4">
              <input type="text" className="form-control border-0" placeholder="Jane"
                onChange={e => changeAttr('name' ,e.target.value)}
                value={contactData.name}/>
              <label htmlFor="name">{t("contact_Name")} *</label>
              {errors?.name ? <div className="invalid-feedback">{errors.name}</div> : null}
            </div>
          </div>
          <div className="col-md-6 en">
            <div className="form-floating mb-4 en">
              <input className="form-control border-0 en" placeholder="jane.doe@example.com"
                onChange={e => changeAttr('email' ,e.target.value)}
                value={contactData.email}/>
              <label htmlFor="email" className="en"> {t("contact_E_mail")} * </label>
              {errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
            </div>
          </div>
          <div className="col-md-6 en">
            <div className="form-floating mb-4 en">
              <input className="form-control border-0 en" placeholder="jane.doe@example.com"
                onChange={e => changeAttr('subject' ,e.target.value)}
                value={contactData.subject}/>
              <label htmlFor="subject" className="en"> {t("contact_subject")} * </label>
              {errors.subject ? <div className="invalid-feedback">{errors.subject}</div> : null}
            </div>
          </div>
          <div className="col-md-6 en">
            <div className="form-floating mb-4 en">
              <input type="number" className="form-control border-0 en" placeholder="jane.doe@example.com"
                onChange={e => changeAttr('phone' ,e.target.value)}
                value={contactData.phone}/>
              <label htmlFor="phone" className="en"> {t("contact_Phone")} * </label>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-floating mb-4">
              <textarea className="form-control border-0" placeholder="Your message"
                onChange={e => changeAttr('description' ,e.target.value)}
                value={contactData.description}
                style={{ height: "150px" }}></textarea>
              <label htmlFor="message">{t("contact_Message")} *</label>
              {errors.description ? <div className="invalid-feedback">{errors.description}</div> : null}
            </div>
          </div>
          <div className="col-12">
            {successMessage ? <div className="alert alert-success">{successMessage}</div> : null}
            <button
              type="submit"
              className="btn btn-outline-primary rounded-pill btn-send mb-3"
              onClick={sendContact}
              disabled={disabled}
            >
              {t("contact_Send")}
              {disabled ? <Spinner/> : null}
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default ContactForm
