import React, { useEffect } from "react";
import "./App.css";
import { useState } from "react"
import AppContext from "./AppContext"
import Config from "./Config";
import AppTemplate from "./AppTemplate";

function App() {
    const [systemLang ,setSystemLang] = useState("en")
    const [loading ,setLoading] = useState(true)

    const providerValue = {
        systemLang: systemLang,
        changeLanguage: lang => {
            const __lang = lang === "ar" ? "ar" : "en"
            Config.changeLayout(__lang)
            localStorage.setItem('systemLang' ,__lang)
            setSystemLang(__lang)
        },
        translate: key => {
            return Config.translateByKey(key ,systemLang)
        }
    }

    useEffect(() => {
        const defaultLang = localStorage.getItem('systemLang')
        if (defaultLang && systemLang !== defaultLang) setSystemLang(defaultLang)
        setTimeout(() => setLoading(false) ,2000)
    } ,[setSystemLang ,setLoading])

    return (
        <AppContext.Provider value={ providerValue }>
            {loading ? <div className="page-loader"></div> : <AppTemplate/>}
        </AppContext.Provider>
    )
}

export default App;
