import { useContext, useState } from "react";
import AppContext from "../../AppContext";

function Payment() {
  const {translate} = useContext(AppContext)
  const t = k => translate(k)
  
  const [status, setStatus] = useState(1);

  const radioHandler = (status) => {
    setStatus(status);
  };

  return (
    <>
      <form onSubmit={e => e.preventDefault()} className="contact-form needs-validation">
        <div className="messages"></div>
        <div className="col-md-12">
          <div className="form-floating mb-4 en">
            <input className="form-check-input"
              disabled={true}
              type="radio"
              // checked={status === 0}
              onClick={(e) => radioHandler(0)}
            />
            <label className="form-check-label en"> {t("Register_card")} "{t("CommingSoon")}"" </label>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-floating mb-4 en">
            <input className="form-check-input"
              type="radio"
              checked={status === 1}
              onChange={(e) => radioHandler(1)}
            />
            <label className="form-check-label en"> {t("Register_Cash")} </label>
          </div>
        </div>

        {status === 0 && (
          <div className="row">
            <div className="col-md-12">
              <div className="form-floating mb-4 en">
                <input type="number"
                  className="form-control en"
                  placeholder="01154258770"
                />
                <label htmlFor="CreditNumber" className="en"> {t("Register_Credit")} </label>
                <div className="invalid-feedback"> Error Message </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-floating mb-4 en">
                <input type="number"
                  className="form-control en"
                  placeholder="01154258770"
                />
                <label htmlFor="NumberOnCard" className="en"> {t("Register_Number")} </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-floating mb-4 en">
                <input type="number"
                  className="form-control en"
                  placeholder="01154258770"
                />
                <label htmlFor="Expiration" className="en"> {t("Register_Exp")} </label>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-floating mb-4 en">
                <input type="number"
                  className="form-control en"
                  placeholder="01154258770"
                />
                <label htmlFor="CVV" className="en"> {t("Register_CVV")} </label>
              </div>
            </div>
          </div>
        )}
        {status === 1 && null}
      </form>
    </>
  );
}

export default Payment;
