import { useContext } from "react";
import AppContext from "../../AppContext";

const Owner = ({data ,setData ,errors}) => {
  const {translate} = useContext(AppContext)
  const t = k => translate(k)
  
  return (
    <>
      <form onSubmit={e => e.preventDefault()} className="contact-form needs-validation">
        <div className="messages"></div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-floating mb-4">
              <input className="form-control"
                onChange={e => setData('fName' ,e.target.value)}
                value={data?.fName}
                placeholder="Jane"
              />
              <label htmlFor="name">{t("Register_first_name")}</label>
              {errors.name ? <div className="invalid-feedback">{errors.name}</div> : null}
            </div>
          </div>
          <div className="col-md-6 en">
            <div className="form-floating mb-4 en">
              <input className="form-control en"
                placeholder="Doe"
                onChange={e => setData('lName' ,e.target.value)}
                value={data?.lName}
              />
              <label className="en"> {t("Register_last_name")} </label>
              {errors?.lName ? <div className="invalid-feedback">{errors.lName}</div> : null}
            </div>
          </div>
          <div className="col-md-12 en">
            <div className="form-floating mb-4 en">
              <input className="form-control en"
                placeholder="jane.doe@example.com"
                onChange={e => setData('email' ,e.target.value)}
                value={data?.email}
              />
              <label htmlFor="email" className="en"> {t("contact_E_mail")} </label>
              {errors.email ? <div className="invalid-feedback">{errors.email}</div> : null}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-floating mb-4 en">
              <input type="number"
                className="form-control en"
                placeholder="01154258770"
                onChange={e => setData('phone' ,e.target.value)}
                value={data?.phone}
              />
              <label htmlFor="phone" className="en"> {t("contact_Phone")} </label>
              {errors.phone ? <div className="invalid-feedback">{errors.phone}</div> : null}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-floating mb-4 en">
              <input className="form-control en"
                placeholder="*******"
                type="password"
                onChange={e => setData('password' ,e.target.value)}
                value={data.password}
              />
              <label htmlFor="password" className="en"> {t("Register_Password")} </label>
              {errors.password ? <div className="invalid-feedback">{errors.password}</div> : null}
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-floating mb-4">
              <input className="form-control en"
                placeholder="******"
                type="password"
                onChange={e => setData('password_confirmation' ,e.target.value)}
                value={data.password_confirmation}
              />
              <label htmlFor="passwordConfirmation" className="en"> {t("Register_Confirm_Password")} </label>
              {errors.password_confirmation ?  <div className="invalid-feedback"> {errors.password_confirmation} </div> : null}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default Owner;
