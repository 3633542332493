import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import Logo from "../assets/img/logo.png";
import "../assets/css/plugins.css";
import "../assets/css/style.css";
import "../assets/css/colors/red.css";
import Config from "../../Config";
import AppContext from "../../AppContext";
import Info from "./Info";

function Navbar() {
  const {translate ,changeLanguage ,systemLang} = useContext(AppContext)
  const t = k => translate(k)
  const [header, setHeader] = useState("header");

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("header");
    } else if (window.scrollY > 270) {
      return setHeader("header2");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  return (
    <>
      <header className={`wrapper bg-soft-primary ${systemLang}`}>
        <nav
          className={`${header} navbar navbar-expand-lg extended navbar-light`}
        >
          <div className="container flex-column">
            <div className="topbar d-flex flex-row w-100 justify-content-between align-items-center ">
              <div className="navbar-brand">
                <Link to="/">
                  <img src={Logo} className="w-100" alt="" />
                </Link>
                {/* <Link to="/">
                  <img
                    src={LogoLight}
                    alt=""
                  />
                </Link> */}
              </div>
              <div className="navbar-other">
                <ul className="navbar-nav flex-row align-items-center">
                  <li className="nav-item">
                    <a
                      href="#!"
                      className="nav-link d-lg-block d-none"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasRight"
                      aria-controls="offcanvasRight"
                    >
                      <i className="uil uil-info-circle"></i>
                    </a>
                  </li>
                  <select
                    value={systemLang}
                    onChange={(e) => {
                      changeLanguage(systemLang === "en" ? "ar" : "en");
                    }}
                    className="nav-item dropdown language-select text-uppercase text-dark d-lg-block d-none"
                  >
                    <option value="en" className="nav-item">
                      <Link className="dropdown-item" to="#!">
                        {t("En")}
                      </Link>
                    </option>
                    <option value="ar" className="nav-item">
                      <Link className="dropdown-item" to="#!">
                        {t("Ar")}
                      </Link>
                    </option>
                  </select>
                  <li className="nav-item d-lg-none">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={Config.boardSignInUrl}
                      className="btn btn-sm btn-primary rounded"
                    >
                      {t("offcanvas_Sign")}
                    </a>
                  </li>
                  <li className="nav-item d-lg-none">
                    <button
                      className="hamburger offcanvas-nav-btn"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                    >
                      <span></span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="navbar-collapse-wrapper bg-white d-flex flex-row align-items-center">
              <div
                className="navbar-collapse offcanvas offcanvas-nav offcanvas-start"
                tabIndex="-1"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
              >
                <div className="offcanvas-header d-lg-none">
                  <h3 className="text-white fs-30 mb-0">Gymme</h3>
                  <button
                    type="button"
                    className="btn-close btn-close-white"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body d-flex flex-column h-100">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to="/">
                        {t("home")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Gyms">
                        {t("Gyms")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Mobile">
                        {t("Mobile")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Contact">
                        {t("contact")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Careers">
                        {t("Careers")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <select
                        value={systemLang}
                        onChange={(e) => {
                          changeLanguage(systemLang === "en" ? "ar" : "en");
                        }}
                        className="nav-item dropdown language-select text-uppercase text-dark nav-link d-header"
                      >
                        <option value="en" className="nav-item">
                          <Link className="dropdown-item" to="#!">
                            {t("En")}
                          </Link>
                        </option>
                        <option value="ar" className="nav-item">
                          <Link className="dropdown-item" to="#!">
                            {t("Ar")}
                          </Link>
                        </option>
                      </select>
                    </li>
                  </ul>
                  <div className="d-lg-none mt-auto pt-6 pb-6 order-4">
                    <a
                      href="mailto:info@gymmeclub.com"
                      className="link-inverse d-block"
                    >
                      info@gymmeclub.com
                    </a>
                    <a
                      href="mailto:support@gymmeclub.com"
                      className="link-inverse d-block"
                    >
                      support@gymmeclub.com
                    </a>
                    <br /> 002 (0109) 19 30 427 <br />
                    <nav className="nav social social-white mt-4">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={Config?.socialMediaLinks?.twitter}
                      >
                        <i className="uil uil-twitter"></i>
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={Config?.socialMediaLinks?.facebook}
                      >
                        <i className="uil uil-facebook-f"></i>
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={Config?.socialMediaLinks?.linkedin}
                      >
                        <i className="uil uil-linkedin"></i>
                      </a>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href={Config?.socialMediaLinks?.insta}
                      >
                        <i className="uil uil-instagram"></i>
                      </a>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="navbar-other ms-auto w-100 d-none d-lg-block">
                <nav className="nav social social-muted justify-content-end text-end align-items-center">
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={Config?.socialMediaLinks?.twitter}
                  >
                    <i className="uil uil-twitter"></i>
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={Config?.socialMediaLinks?.facebook}
                  >
                    <i className="uil uil-facebook-f"></i>
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={Config?.socialMediaLinks?.linkedin}
                  >
                    <i className="uil uil-linkedin"></i>
                  </a>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={Config?.socialMediaLinks?.insta}
                  >
                    <i className="uil uil-instagram"></i>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={Config.boardSignInUrl}
                    className="btn btn-sm btn-primary rounded"
                  >
                    {t("offcanvas_Sign")}
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </nav>
        <Info />
        <div
          className={`modal fade ${systemLang}`}
          id="modal-signin"
          tabIndex="-1"
        >
          <div className="modal-dialog modal-dialog-centered modal-sm">
            <div className="modal-content text-center">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <h2 className="mb-3 text-start">{t("model")}</h2>
                <p className="lead mb-6 text-start">{t("model_Fill")}</p>
                <form className="text-start mb-3">
                  <div className="form-floating mb-4">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      id="loginEmail"
                    />
                    <label htmlFor="loginEmail">{t("model_Email")}</label>
                  </div>
                  <div className="form-floating password-field mb-4">
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      id="loginPassword"
                    />
                    <span className="password-toggle">
                      <i className="uil uil-eye"></i>
                    </span>
                    <label htmlFor="loginPassword">{t("model_Password")}</label>
                  </div>
                  <a className="btn btn-primary rounded-pill btn-login w-100 mb-2">
                    {t("offcanvas_Sign")}
                  </a>
                </form>
                <p className="mb-1">
                  <a href="#" className="hover">
                    {t("model_Forgot")}
                  </a>
                </p>
                <p className="mb-0">
                  {t("model_account")}{" "}
                  <a
                    href="#"
                    data-bs-target="#modal-signup"
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    className="hover"
                  >
                    {t("model_Sign")}
                  </a>
                </p>
                <div className="divider-icon my-4">or</div>
                <nav className="nav social justify-content-center text-center">
                  <a href="#" className="btn btn-circle btn-sm btn-google">
                    <i className="uil uil-google"></i>
                  </a>
                  <a href="#" className="btn btn-circle btn-sm btn-facebook-f">
                    <i className="uil uil-facebook-f"></i>
                  </a>
                  <a href="#" className="btn btn-circle btn-sm btn-twitter">
                    <i className="uil uil-twitter"></i>
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navbar;
